import React, { useEffect, useState } from "react"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
  User,
} from "../../../../../entities"
import Layout from "../../../../../components/layout/Layout"
import { UserService } from "../../../../../services/UserService"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { GrEdit } from "react-icons/gr"
import DataTable from "../../../../../components/dataTable/DataTable"

export default function LicensedAdminListPage() {
  const navigate = useNavigate()

  const [users, setUsers] = useState<User[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    load()
  }, [])

  const load = async (page = 1) => {
    setLoading(true)
    const roleFilter = new SearchFilter({
      key: "roleCode",
      value: "licensed-admin",
    })
    const params = new SearchParams([roleFilter], 1, 9999)
    const result = await UserService.search(params)

    console.log(result.info)
    setUsers(result.data)
    setResultInfo(result.info)
    setLoading(false)
  }

  const renderActions = (params: any) => {
    return (
      <div className="table-action-button-container">
        <Button onClick={() => navigate(`/licenciado/admin/${params.id}`)}>
          <GrEdit />
        </Button>
        {/* <Button onClick={() => deleteItem(params.id)} className="table-action-button"><GrTrash /></Button> */}
      </div>
    )
  }

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "email", headerName: "E-mail" },
    { headerName: "Ações", renderCell: renderActions },
  ]

  return (
    <Layout internal={true}>
      <DataTable
        title="Administradores do Licenciado"
        checkboxSelection={false}
        rows={users}
        columns={columns}
        pagination={resultInfo}
        onPageChange={(page: number) => load(page)}
        loading={loading}
      />
    </Layout>
  )
}
