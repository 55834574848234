import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase2Week5.scss';


export default function Phase2Week5(props: ExerciseProps) {
  const [data, setData] = useState({
    targetAudience: props.answer.answer?.targetAudience || '',
    mainCompetitors: props.answer.answer?.mainCompetitors || '',
  })

  const [targets, setTargets] = useState<string[]>([])

  const changeTarget = (target: string) => {
    const idx = targets.findIndex(t => t === target)

    if(idx === -1){
      setTargets([...targets, target]) 
    }else{
      const newTargets = [...targets]
      newTargets.splice(idx, 1)
      setTargets(newTargets)
    }
  }

  useEffect(() => {
    if(Array.isArray(props.answer.answer?.targets))
      setTargets(props.answer.answer.targets)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const isCompleted = data.targetAudience.length >= 1 && data.mainCompetitors.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  useEffect(() => {
    const isCompleted = targets.length >= 1
    const answer = new Answer({
      ...props.answer, 
      answer: {
        targets
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [targets])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase2-week5-form" action="">
          <Row>
            <Col md={3}>
              <button 
                type="button" 
                onClick={(event) => changeTarget('geolocation')}
                className={`${targets.findIndex(t => t === 'geolocation') >= 0 ? 'active' : 'not-active'}`}
              >
                Geolocalização
              </button>
            </Col>
            <Col md={3}>
              <button 
                type="button" 
                onClick={(event) => changeTarget('community')}
                className={`${targets.findIndex(t => t === 'community') >= 0 ? 'active' : 'not-active'}`}
              >
                Comunidade
              </button>
            </Col>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('culture')}
                className={`${targets.findIndex(t => t === 'culture') >= 0 ? 'active' : 'not-active'}`}
              >
                Cultura
              </button>
            </Col>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('habit')}
                className={`${targets.findIndex(t => t === 'habit') >= 0 ? 'active' : 'not-active'}`}
              >
                Hábito
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('limitedMarket')}
                className={`${targets.findIndex(t => t === 'limitedMarket') >= 0 ? 'active' : 'not-active'}`}
              >
                Mercado Limitado
              </button>
            </Col>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('regulation')}
                className={`${targets.findIndex(t => t === 'regulation') >= 0 ? 'active' : 'not-active'}`}
              >
                Regulação
              </button>
            </Col>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('ageGroup')}
                className={`${targets.findIndex(t => t === 'ageGroup') >= 0 ? 'active' : 'not-active'}`}
              >
                Grupo Etário
              </button>
            </Col>
            <Col md={3}>
              <button
                type="button" 
                onClick={(event) => changeTarget('third')}
                className={`${targets.findIndex(t => t === 'third') >= 0 ? 'active' : 'not-active'}`}
              >
                Terceiro
              </button>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="targetAudience"
                  name="targetAudience"
                  type="number"
                  required
                  value={data.targetAudience}
                  onChange={(event) => setData({...data, targetAudience: event.target.value})}
                />
                <Label for="targetAudience">
                  Tamanho do Público Alvo na Praça
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="mainCompetitors"
                  name="mainCompetitors"
                  type="text"
                  required
                  value={data.mainCompetitors}
                  onChange={(event) => setData({...data, mainCompetitors: event.target.value})}
                />
                <Label for="mainCompetitors">
                  Três Principais Concorrentes na Praça
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}