import React from 'react';
import Layout from '../../components/layout/Layout';
import Report from '../../components/report/Report';

//import './ReportPage.scss';


export default function ReportPage() {
  return (
    <Layout>
      <Report />
    </Layout>
  );
}