import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class WhiteLabelRepository {
  public static async resolve(hostname: string): Promise<string> {
    const url = `/whitelabel/resolve?hostname=${hostname}`
    const response = await client.DoRequest('GET', url)

    return response.id || ''
  }

  public static async getConfigs(id: string): Promise<any> {
    const url = `/whitelabel/config/${id}`
    const response = await client.DoRequest('GET', url)

    return response || {}
  }
}