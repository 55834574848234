import React from 'react';

import './AboutUsExtension.scss';


export default function AboutUsExtension() {
  return (
    <section className="about-us-extension-container">
      <img src="/images/homepage/rocket.png" alt="Ícone animado do foguete" />
    </section>
  );
}