import React, { useEffect, useState } from "react"
import { Col, Row } from "reactstrap"
import { Answer } from "../../../entities"
import { ExerciseProps } from "../base"

import "./HypothesisListing.scss"
interface Hypothesis {
  id: number
  hypothesis: string
  type: string
}

export default function HypothesisListing(props: ExerciseProps) {
  const [hypotheses, setHypoteses] = useState<Hypothesis[]>([])

  useEffect(() => {
    const hyposDb: Hypothesis[] = props.answer.answer?.hypotheses || []

    const hypos: Hypothesis[] = []
    for (let i = 1; i <= 3; i++) {
      const hypothesis = {
        id: i,
        hypothesis: "",
        type: "",
      }

      const idx = hyposDb.findIndex((item) => item.id === i)
      if (idx >= 0) {
        hypothesis.hypothesis = hyposDb[idx]?.hypothesis
        hypothesis.type = hyposDb[idx]?.type
      }
      hypos.push(hypothesis)
    }
    setHypoteses(hypos)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const isCompleted = hypotheses.length >= 1
    const answer = new Answer({
      ...props.answer,
      answer: {
        hypotheses,
      },
      isCompleted,
    })

    props.save(answer)
    // eslint-disable-next-line
  }, [hypotheses])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>
            {props.phase.name} - {props.week.name}
          </h1>
          <h2>
            Adicione cada item do seu levantamento de hipóteses de acordo com as
            descrições:
          </h2>
        </div>
        <form className="hypothesis-listing-form" action="">
          {hypotheses.map((hypothesis: Hypothesis, idx: number) => (
            <Row key={`hyphotesis-${idx}`}>
              <Col md={12}>
                <h3>{idx + 1}ª Hipótese</h3>
                <div className="hypothesis-listing-form-field">
                  <input
                    type="text"
                    name={`hypothesis${idx}`}
                    placeholder="Descrição da Hipótese"
                    value={hypothesis.hypothesis}
                    onChange={(event) => {
                      const hypos = [...hypotheses]
                      hypos[idx].hypothesis = event.target.value
                      setHypoteses(hypos)
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      const hypos = [...hypotheses]
                      hypos[idx].type = "simple"
                      setHypoteses(hypos)
                    }}
                    className={`${
                      hypothesis.type === "simple" ? "active" : "not-active"
                    }`}
                  >
                    Simples
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const hypos = [...hypotheses]
                      hypos[idx].type = "intermediate"
                      setHypoteses(hypos)
                    }}
                    className={`${
                      hypothesis.type === "intermediate"
                        ? "active"
                        : "not-active"
                    }`}
                  >
                    Intermediária
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const hypos = [...hypotheses]
                      hypos[idx].type = "critical"
                      setHypoteses(hypos)
                    }}
                    className={`${
                      hypothesis.type === "critical" ? "active" : "not-active"
                    }`}
                  >
                    Crítica
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      const hypos = [...hypotheses]
                      hypos[idx].type = "deathHypothesis"
                      setHypoteses(hypos)
                    }}
                    className={`${
                      hypothesis.type === "deathHypothesis"
                        ? "active"
                        : "not-active"
                    }`}
                  >
                    Hipótese da Morte
                  </button>
                </div>
              </Col>
            </Row>
          ))}
        </form>
      </section>
    </div>
  )
}
