import { BusinessSpecialty, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class BusinessSpecialtyRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<BusinessSpecialty>> {
    const headers = { Authorization: token };
    const response = await client.DoRequest('GET', `/businessSpecialty?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<BusinessSpecialty>(response.data, response.info)

    return result
  }
}