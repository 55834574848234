import React from 'react';
import { TabPane } from 'reactstrap';

interface TabPanelItemProps {
  tabName: string
  children: React.ReactNode
  index?: number
}

export default function TabPanelItem(props: TabPanelItemProps) {

  return (
    <TabPane tabId={props.index}>
      {props.children}
    </TabPane>
  );
}