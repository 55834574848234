import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import { ExerciseProps } from '../base';
//import { Answer } from '../../../entities';

import './Phase2Week1.scss';
import { Answer } from '../../../entities';


export default function Phase2Week1(props: ExerciseProps) {
  const [targets, setTargets] = useState<string[]>([])

  const changeTarget = (target: string) => {
    const idx = targets.findIndex(t => t === target)

    if(idx === -1){
      setTargets([...targets, target]) 
    }else{
      const newTargets = [...targets]
      newTargets.splice(idx, 1)
      setTargets(newTargets)
    }
  }

  useEffect(() => {
    if(Array.isArray(props.answer.answer?.targets))
      setTargets(props.answer.answer.targets)

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const isCompleted = targets.length >= 1
    const answer = new Answer({
      ...props.answer, 
      answer: {
        targets
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [targets])
  
  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Selecione o tipo de público para o qual sua solução é projetada para que possamos seguir
            ao detalhamento do perfil:
          </h2>
        </div>
        <form className="phase2-week1-form" action="">
          <Row>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2c')}
                className={`${targets.findIndex(t => t === 'b2c') >= 0 ? 'active' : 'not-active'}`}
                  >B2C
              </button>
            </Col>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2e')}
                className={`${targets.findIndex(t => t === 'b2e') >= 0 ? 'active' : 'not-active'}`}
                  >B2E
              </button>
            </Col>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2b')}
                className={`${targets.findIndex(t => t === 'b2b') >= 0 ? 'active' : 'not-active'}`}
                  >B2B
              </button>
            </Col>
          </Row>
          <Row>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2g')}
                className={`${targets.findIndex(t => t === 'b2g') >= 0 ? 'active' : 'not-active'}`}
                  >B2G
              </button>
            </Col>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2b2c')}
                className={`${targets.findIndex(t => t === 'b2b2c') >= 0 ? 'active' : 'not-active'}`}
                  >B2B2C
              </button>
            </Col>
            <Col>
              <button 
                type="button" 
                onClick={(event) => changeTarget('b2b2e')}
                className={`${targets.findIndex(t => t === 'b2b2e') >= 0 ? 'active' : 'not-active'}`}
                  >B2B2E
              </button>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}