import React, { useState } from "react"
import { Link } from "react-router-dom"
import UserStorage from "../../util/userStorage"
import { Flex, Image } from "@chakra-ui/react"

import "./Header.scss"

import { useWhitelabel } from "../../stores/WhiteLabelStore"

interface HeaderProps {
  internal: boolean
}

export default function Header(props: HeaderProps) {
  const whitelabel = useWhitelabel().get()

  const [isActive, setIsActive] = useState(false)
  const user = UserStorage.get()

  const scrollSection = (id: string) => {
    console.log(window.location.pathname)
    if (window.location.pathname === "/") {
      const section = document.querySelector(`#${id}`)
      section?.scrollIntoView({ behavior: "smooth" })
      return false
    }
  }

  return (
    <header id="header" className="header">
      <section className="header-content">
        <div className="logo">
          <Link to="/">
            <Image src={whitelabel?.logo?.url} alt="Logo da Valide.se" />
          </Link>
        </div>
        <nav className="navbar">
          <ul
            className={`nav-list ${
              isActive === true ? "toggle-mobile-menu" : ""
            }`}
          >
            {whitelabel?.home === true && (
            <li>
              <Link onClick={() => scrollSection("header")} to="">
                Home
              </Link>
            </li>
            )}
            {(!UserStorage.hasToken() && whitelabel?.home === true) && (
              <>
                <li>
                  <Link onClick={() => scrollSection("about-us")} to="">
                    Sobre
                  </Link>
                </li>
                <li>
                  <Link onClick={() => scrollSection("hacktown")} to="">
                    HackTown
                  </Link>
                </li>
                <li>
                  <Link onClick={() => scrollSection("contact")} to="">
                    Fale Conosco
                  </Link>
                </li>
                <li className="mobile-access-button">
                  <Link to="/login">Entrar</Link>
                </li>
                <li className="mobile-access-button">
                  <Link to="/cadastrar">Cadastrar</Link>
                </li>
              </>
            )}
            {UserStorage.hasToken() && (
              <>
                <li className="mobile-access-button">
                  <Link to="/painel">Painel Startup</Link>
                </li>
                <li>
                  <Link to="/painel">Dashboard</Link>
                </li>
                {user?.role?.code === "admin" && (
                  <>
                    <li>
                      <Link to="/licenciado">Licenciados</Link>
                    </li>
                    <li>
                      <Link to="/startup">Startups</Link>
                    </li>
                    <li>
                      <Link to="/mentor">Mentores</Link>
                    </li>
                    <li>
                      <Link to="/investidor">Investidores</Link>
                    </li>
                    <li>
                      <Link to="/master/me">Perfil</Link>
                    </li>
                  </>
                )}
                {user?.role?.code === "licensed" && (
                  <>
                    {/* <li><Link to="/licenciado/admin">Administradores</Link></li> */}
                    <li>
                      <Link to="/startup">Startups</Link>
                    </li>
                    <li>
                      <Link to="/mentor">Mentores</Link>
                    </li>
                    <li>
                      <Link to="/investidor">Investidores</Link>
                    </li>
                    <li>
                      <Link to="/licenciado/me">Perfil</Link>
                    </li>
                  </>
                )}
                {user?.role?.code === "startup" && (
                  <>
                    <li>
                      <Link to="/acceleration/646ceeafa38ce7c9a80471d9">
                        Fases
                      </Link>
                    </li>
                    <li>
                      <Link to="/startup/me">Perfil</Link>
                    </li>
                  </>
                )}
                {user?.role?.code === "founder" && (
                  <>
                    <li>
                      <Link to="/acceleration/646ceeafa38ce7c9a80471d9">
                        Fases
                      </Link>
                    </li>
                    <li>
                      <Link to="/startup/id">Perfil</Link>
                    </li>
                    <li>
                      <Link to="/startup/fundador/me">Perfil</Link>
                    </li>
                  </>
                )}
                {user?.role?.code === "mentor" && (
                  <>
                    <li>
                      <Link to="/mentor/me">Perfil</Link>
                    </li>
                  </>
                )}
                {user?.role?.code === "investor" && (
                  <>
                    <li>
                      <Link to="/investidor/me">Perfil</Link>
                    </li>
                  </>
                )}
                <li>
                  <Link to="/perfil/alterar-senha">Alterar Senha</Link>
                </li>
                <li>
                  <Link to="/central-de-ajuda">Central de Ajuda</Link>
                </li>
                <li>
                  <Link to="/" onClick={() => UserStorage.logout()}>
                    Sair
                  </Link>
                </li>
              </>
            )}
          </ul>
          {whitelabel?.home === true && (
          <ul className="access-links">
            <li>
              <Link onClick={() => scrollSection("header")} to="/">
                Home
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollSection("about-us")} to="/">
                Sobre
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollSection("hacktown")} to="">
                HackTown
              </Link>
            </li>
            <li>
              <Link onClick={() => scrollSection("contact")} to="">
                Fale Conosco
              </Link>
            </li>
          </ul>
          )}
          <div className="access-buttons">
            {!UserStorage.hasToken() && (
              <>
                <div className="login-button">
                  <Link to="/login">Entrar</Link>
                </div>
                <div className="register-button">
                  <Link to="/cadastrar">Cadastrar</Link>
                </div>
              </>
            )}
            {UserStorage.hasToken() && (
              <div className="startup-login-button">
                <Link to="/painel">Painel Startup</Link>
              </div>
            )}
          </div>
        </nav>
        <div className="header-menu-burger">
          <input
            type="checkbox"
            id="checkbox-menu"
            onClick={() => {
              setIsActive(!isActive)
            }}
          />
          <label htmlFor="checkbox-menu">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
      </section>
    </header>
  )
}
