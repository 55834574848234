import React from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import {
  Flex,
  Text,
  Image,
  Box,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Checkbox,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Link as ChakraLink,
} from "@chakra-ui/react"
import { ChevronRightIcon } from "@chakra-ui/icons"
import DataTablePagination, {
  PaginationProps,
} from "./pagination/DataTablePagination"

import "./DriveTable.scss"

import { useWhitelabel } from "../../stores/WhiteLabelStore"

interface DataTableRow {
  headerName: string
  field?: string
  renderCell?: (params: any, idx: number) => any
}

interface TypeProps {
  title?: string
  caption?: string
  rows: any[]
  columns: DataTableRow[]
  checkboxSelection?: boolean
  pagination?: PaginationProps
  onPageChange?: (page: number) => void
  loading?: boolean
}

export default function DriveTable(props: TypeProps) {
  const whitelabel = useWhitelabel().get()

  const getFieldValue = (row: any, fieldName: string) => {
    if (fieldName.includes(".") === false) return row[fieldName]

    const fieldNames = fieldName.split(".")
    let value = row
    for (const fname of fieldNames) {
      value = value[fname] || ""
    }
    return typeof value === "string" ? value : ""
  }

  return (
    <Flex
      my="2rem"
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex
        minH="600px"
        w="100%"
        maxWidth="1600px"
        p="2rem"
        bg="rgb(252, 252, 252)"
        borderRadius="1.5rem"
        direction="column"
      >
        {/* <Flex w="100%" my="1rem" alignItems="flex-start">
          <Breadcrumb
            w="100%"
            spacing="8px"
            separator={<ChevronRightIcon color={whitelabel?.colors?.primary} />}
            fontSize="1.5rem"
          >
            <BreadcrumbItem>
              <BreadcrumbLink href="#" _hover={{ color: `${whitelabel?.colors?.primary}` }}>
                Meus Arquivos
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#" _hover={{ color: `${whitelabel?.colors?.primary}` }}>
                Arquivo 1
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#" _hover={{ color: `${whitelabel?.colors?.primary}` }}>
                Arquivo 2
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#" _hover={{ color: `${whitelabel?.colors?.primary}` }}>
                Arquivo 3
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
              <BreadcrumbLink href="#" _hover={{ color: `${whitelabel?.colors?.primary}` }}>
                Arquivo 4
              </BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage color={whitelabel?.colors?.secondary}>
              <BreadcrumbLink href="#">Arquivo 5</BreadcrumbLink>
            </BreadcrumbItem>
          </Breadcrumb>
        </Flex> */}

        <Flex
          h="100%"
          w="100%"
          justifyContent="space-between"
          direction="column"
        >
          <TableContainer
            w="100%"
            maxH="600px"
            overflowX="scroll"
            className="drive-table"
          >
            <Table variant="simple" mb="2rem" className="drive-table-style">
              {props.caption && <TableCaption>{props.caption}</TableCaption>}
              <Thead>
                <Tr>
                  {props.checkboxSelection !== false && (
                    <Th>
                      <Checkbox />
                    </Th>
                  )}
                  {props.columns.map((column: any, idx: number) => (
                    <Th
                      color={whitelabel?.colors?.primary}
                      key={`column-header-${idx}`}
                    >
                      {column.headerName}
                    </Th>
                  ))}
                </Tr>
              </Thead>
              <Tbody>
                {props.checkboxSelection !== false && (
                  <Td>
                    <Checkbox />
                  </Td>
                )}
                {props.rows.map((row: DataTableRow, idxRow: number) => (
                  <Tr key={`table-tr-${idxRow}`}>
                    {/* {props.checkboxSelection !== false && (
                      <Td>
                        <input
                          type="checkbox"
                          name="selectAll"
                          id="selectAll"
                        />
                      </Td>
                    )} */}
                    {props.columns.map((column: any, idxCol: number) => (
                      <Td key={`table-td-${idxRow}-${idxCol}`}>
                        {column.renderCell ? (
                          <>
                            <Flex gap="1rem" className="drive-table-content">
                              {column.renderCell(row, idxRow)}
                            </Flex>
                          </>
                        ) : (
                          <>{getFieldValue(row, column.field || "")}</>
                        )}
                      </Td>
                    ))}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          {/* <Flex w="100%">
            {props.pagination && (
              <DataTablePagination {...props.pagination} {...props} />
            )}
          </Flex> */}
        </Flex>
      </Flex>
    </Flex>
  )
}
