import { Select } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Layout from "../../../../components/layout/Layout";
import { Acceleration, SearchFilter, SearchParams, User } from "../../../../entities";
import { AccelerationService } from "../../../../services";
import { UserService } from "../../../../services/UserService";
import UserStorage from "../../../../util/userStorage";

export default function AccelerationFormPage() {
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedMentor, setSelectedMentor] = useState<User | null>(null);
  const [startups, setStartups] = useState<User[]>([]);
  const [mentors, setMentors] = useState<User[]>([]);
  const navigate = useNavigate();
  const params = useParams();

  const [user, setUser] = useState(new User());
  const [acceleration, setAcceleration] = useState(new Acceleration({
    startups: [],
    mentors: []
  }));

  useEffect(() => {
    const userData = UserStorage.get();
    if (userData?.role?.code === "licensed") {
      setUser(userData);
    }
  }, []);

  useEffect(() => {
    load(); 
    if (params.id && params.id !== "0") {
      loadAcceleration(params.id); 
    } else {
      // Caso para nova aceleração
      setAcceleration(new Acceleration({
        startups: [],
        mentors: []
      }));
    }
  }, [params.id]);

  const loadAcceleration = async (id: string) => {
    const response = await AccelerationService.get(id);
    setAcceleration(new Acceleration({
      ...response,
      startups: response.startups || [],
      mentors: response.mentors || [],
    }));
  };

  const load = async () => {
    const startupParams = new SearchParams([new SearchFilter({ key: "roleCode", value: "startup" })], 1, 9999);
    const startupResult = await UserService.search(startupParams);

    const mentorParams = new SearchParams([new SearchFilter({ key: "roleCode", value: "mentor" })], 1, 9999);
    const mentorResult = await UserService.search(mentorParams);

    setStartups(startupResult.data);
    setMentors(mentorResult.data);
  };

  const addStartup = () => {
    if (!selectedUser) {
      alert("Selecione uma startup.");
      return;
    }

    if (acceleration.startups.some((s) => s.id === selectedUser.id)) {
      alert("Esta startup já foi adicionada.");
      return;
    }

    setAcceleration(prev => new Acceleration({
      ...prev,
      startups: [...prev.startups, selectedUser]
    }));
    setStartups(prev => prev.filter(s => s.id !== selectedUser.id));
    setSelectedUser(null);
  };

  const removeStartup = (startup: User) => {
    setAcceleration(prev => new Acceleration({
      ...prev,
      startups: prev.startups.filter((s) => s.id !== startup.id)
    }));
    setStartups(prev => [...prev, startup]);
  };

  const addMentor = () => {
    if (!selectedMentor) {
      alert("Selecione um mentor.");
      return;
    }

    if (acceleration.mentors.some((m) => m.id === selectedMentor.id)) {
      alert("Este mentor já foi adicionado.");
      return;
    }

    setAcceleration(prev => new Acceleration({
      ...prev,
      mentors: [...prev.mentors, selectedMentor]
    }));
    setMentors(prev => prev.filter(m => m.id !== selectedMentor.id));
    setSelectedMentor(null);
  };

  const removeMentor = (mentor: User) => {
    setAcceleration(prev => new Acceleration({
      ...prev,
      mentors: prev.mentors.filter((m) => m.id !== mentor.id)
    }));
    setMentors(prev => [...prev, mentor]);
  };

  const save = async (event: React.FormEvent) => {
    event.preventDefault();

    if (acceleration.name && acceleration.name.length < 3) {
      alert("Digite o nome.");
      return;
    }

    await AccelerationService.save(acceleration);
    navigate("/acceleration");
  };

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form onSubmit={save}>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>{params.id === "0" ? "Novo Ciclo de Aceleração" : "Editar Ciclo de Aceleração"}</h1>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="name"
                  name="name"
                  placeholder="Nome do Ciclo de Aceleração"
                  type="text"
                  required
                  value={acceleration.name}
                  onChange={(event) =>
                    setAcceleration(new Acceleration({
                      ...acceleration,
                      name: event.target.value,
                    }))
                  }
                />
                <Label for="name">Nome do Ciclo de Aceleração</Label>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Adicionar Startups</h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Select
                  placeholder="Selecione uma Startup"
                  value={selectedUser?.id || ""}
                  onChange={(event) => {
                    const id = event.target.value;
                    const startup = startups.find((s) => s.id === id);
                    if (startup) setSelectedUser(startup);
                  }}
                >
                  {startups.map((startup, key) => (
                    <option value={startup.id} key={key}>
                      {startup.attributes?.find(
                        (attr: { code: string }) => attr.code === "name"
                      )?.value || "Nome não encontrado"}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
            <Col md={2} className="profile-forms-add-button-container">
              <Button onClick={addStartup}>Adicionar</Button>
            </Col>
          </Row>

          {acceleration.startups.map((item) => (
            <Row key={item.id}>
              <Col md={2}>
                {item.attributes?.find(
                  (attr: { code: string }) => attr.code === "name"
                )?.value || "Nome não encontrado"}
              </Col>
              <Col md={2}>
                <Button onClick={() => removeStartup(item)}>Remover</Button>
              </Col>
            </Row>
          ))}

          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Adicionar Mentores</h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Select
                  placeholder="Selecione um Mentor"
                  value={selectedMentor?.id || ""}
                  onChange={(event) => {
                    const id = event.target.value;
                    const mentor = mentors.find((m) => m.id === id);
                    if (mentor) setSelectedMentor(mentor);
                  }}
                >
                  {mentors.map((mentor, key) => (
                    <option value={mentor.id} key={key}>
                      {mentor.attributes?.find(
                        (attr: { code: string }) => attr.code === "name"
                      )?.value || "Nome não encontrado"}
                    </option>
                  ))}
                </Select>
              </FormGroup>
            </Col>
            <Col md={2} className="profile-forms-add-button-container">
              <Button onClick={addMentor}>Adicionar</Button>
            </Col>
          </Row>

          {acceleration.mentors.map((mentor) => (
            <Row key={mentor.id}>
              <Col md={2}>
                {mentor.attributes?.find(
                  (attr: { code: string }) => attr.code === "name"
                )?.value || "Nome não encontrado"}
              </Col>
              <Col md={2}>
                <Button onClick={() => removeMentor(mentor)}>Remover</Button>
              </Col>
            </Row>
          ))}

          <Row>
            <Col md={12}>
              <Button type="submit">Salvar</Button>
            </Col>
          </Row>
        </Form>
      </section>
    </Layout>
  );
}
