import { ExerciseType } from "./ExerciseType"

export class Exercise {
  private _id: string
      public id: string
      public type: ExerciseType|undefined
      public name: string|undefined
      public sort: number|undefined
  
  public constructor(data: any) {
    this._id = data.id || data._id
          
        this.id = data.id
                
        if (data.type) {
            this.type = new ExerciseType(data.type)
        }
                
        this.name = data.name
                
        this.sort = data.sort
              
  }
}