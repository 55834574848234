import React from "react"
import { Table } from "reactstrap"
import DataTablePagination, {
  PaginationProps,
} from "./pagination/DataTablePagination"

import "./DataTable.scss"
import { Link } from "react-router-dom"

interface DataTableRow {
  headerName: string
  field?: string
  renderCell?: (params: any, idx: number) => any
}

interface TypeProps {
  title?: string
  rows: any[]
  columns: DataTableRow[]
  checkboxSelection?: boolean
  pagination?: PaginationProps
  onPageChange?: (page: number) => void
  loading?: boolean
  newLink?: string
}

export default function DataTable(props: TypeProps) {
  const getFieldValue = (row: any, fieldName: string) => {
    if (fieldName.includes(".") === false) return row[fieldName]

    const fieldNames = fieldName.split(".")
    let value = row
    for (const fname of fieldNames) {
      value = value[fname] || ""
    }
    return typeof value === "string" ? value : ""
  }

  return (
    <section className="data-table-section">
      {props.title && (
        <>
          <div className="data-table-title">
            <h1>{props.title}</h1>
          </div>
          {props.newLink && (
            <div className="data-table-button">
              <Link to={props.newLink} className="btn">
                Novo
              </Link>
            </div>
          )}
        </>
      )}
      <div className="data-table-container">
        <Table className="data-table" bordered>
          <thead>
            <tr>
              {props.checkboxSelection !== false && (
                <th>
                  <input type="checkbox" name="selectAll" id="selectAll" />
                </th>
              )}
              {props.columns.map((column: any, idx: number) => (
                <th key={`column-header-${idx}`}>{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {props.rows.map((row: DataTableRow, idxRow: number) => (
              <tr key={`table-tr-${idxRow}`}>
                {props.checkboxSelection !== false && (
                  <td>
                    <input type="checkbox" name="selectAll" id="selectAll" />
                  </td>
                )}
                {props.columns.map((column: any, idxCol: number) => (
                  <td key={`table-td-${idxRow}-${idxCol}`}>
                    {column.renderCell ? (
                      <>{column.renderCell(row, idxRow)}</>
                    ) : (
                      <>{getFieldValue(row, column.field || "")}</>
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {props.pagination && (
        <DataTablePagination {...props.pagination} {...props} />
      )}
    </section>
  )
}
