import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
//import { Answer } from '../../../entities';

import './Phase2Week2.scss';
import { Answer } from '../../../entities';


export default function Phase2Week2(props: ExerciseProps) {

  const [data, setData] = useState({
    adLink: props.answer.answer?.adLink || '',
    introductionLink: props.answer.answer?.introductionLink || '',
    contentLink: props.answer.answer?.contentLink || '',
    specialConditionLink: props.answer.answer?.specialConditionLink || '',
    recognitionLink: props.answer.answer?.recognitionLink || '',
    ctaLink: props.answer.answer?.ctaLink || '',
  })

  useEffect(() => {
    const isCompleted = data.adLink.length >= 1 && data.introductionLink.length >= 1 && data.contentLink.length >= 1 
    && data.specialConditionLink.length >= 1 && data.recognitionLink.length >= 1 && data.ctaLink.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Adicione as informações necessárias para a composição de sua estratégia de funil de lançamento
            de produto:
          </h2>
        </div>
        <form className="phase2-week2-form" action="">
          <Row>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                  <Input
                      id="adLink"
                      name="adLink"
                      type="text"
                      required
                      value={data.adLink}
                      onChange={(event) => setData({...data, adLink: event.target.value})}
                    />
                    <Label for="adLink">
                      Link de Anúncio
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                    <Input
                      id="introductionLink"
                      name="introductionLink"
                      type="text"
                      required
                      value={data.introductionLink}
                      onChange={(event) => setData({...data, introductionLink: event.target.value})}
                    />
                    <Label for="introductionLink">
                      Link de Introdução
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                  <Input
                      id="contentLink"
                      name="contentLink"
                      type="text"
                      required
                      value={data.contentLink}
                      onChange={(event) => setData({...data, contentLink: event.target.value})}
                    />
                    <Label for="contentLink">
                      Link de Conteúdo
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                    <Input
                      id="specialConditionLink"
                      name="specialConditionLink"
                      type="text"
                      required
                      value={data.specialConditionLink}
                      onChange={(event) => setData({...data, specialConditionLink: event.target.value})}
                    />
                    <Label for="specialConditionLink">
                      Link de Condição Especial
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                  <Input
                      id="recognitionLink"
                      name="recognitionLink"
                      type="text"
                      required
                      value={data.recognitionLink}
                      onChange={(event) => setData({...data, recognitionLink: event.target.value})}
                    />
                    <Label for="recognitionLink">
                      Link de Reconhecimento
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                    <Input
                      id="ctaLink"
                      name="ctaLink"
                      type="text"
                      required
                      value={data.ctaLink}
                      onChange={(event) => setData({...data, ctaLink: event.target.value})}
                    />
                    <Label for="ctaLink">
                      Link de Call to Action
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}