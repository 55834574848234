import React from "react"
import { Flex, Text, Image } from "@chakra-ui/react"

import { useWhitelabel } from "../../../stores/WhiteLabelStore"

export default function DashboardInvestor() {
  const whitelabel = useWhitelabel().get()

  return (
    <Flex
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex w="100%" maxWidth="1440px" p="1rem" direction="column">
        <Text
          color={whitelabel?.colors?.quinary}
          fontSize={{ base: "1.5rem", sm: "2rem", md: "2rem", lg: "2rem" }}
          fontWeight="600"
          alignSelf="center"
          bg={whitelabel?.colors?.secondary}
          p="0.25rem 1.5rem"
          borderRadius="0.5rem"
        >
          Dashboard do Investidor
        </Text>

        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Informações do Investidor
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Número de Startups Investidas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                44
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Ticket Médio Aportado
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 125.000,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Total Aportado
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 1.158.000,00
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" my="2rem">
          <Flex
            alignSelf={{ base: "center", sm: "flex-start" }}
            direction="column"
            w="100%"
            maxW="250px"
            minH="150px"
            px="0.5rem"
            justifyContent="space-between"
            alignItems="center"
          >
            <Text
              color={whitelabel?.colors?.secondary}
              fontSize="1.25rem"
              fontWeight="600"
            >
              Segmentos Mais Aportados
            </Text>
            <Flex
              gap="0.5rem"
              alignItems="center"
              justifyContent="center"
              flexWrap="wrap"
            >
              <Text
                p="0.25rem 1rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                Tech
              </Text>
              <Text
                p="0.25rem 1rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                FinTech
              </Text>
              <Text
                p="0.25rem 1rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                FoodTech
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Startups Investidas
          </Text>
          <Flex
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            flexWrap="wrap"
          >
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup A
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup B
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup C
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
