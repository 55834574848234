import { Acceleration, SearchParams, SearchResult } from "../../entities";
import { AccelerationRepository } from "../../repositories/startup";
import UserStorage from "../../util/userStorage";

export class AccelerationService {
  public static async search(
    params: SearchParams
  ): Promise<SearchResult<Acceleration>> {
    const token = UserStorage.getToken();
    const result = await AccelerationRepository.search(params, token);
    return result;
  }

  public static async get(id: string): Promise<Acceleration> {
    const token = UserStorage.getToken();
    const acceleration = await AccelerationRepository.get(id, token);
    return acceleration;
  }

  public static async save(acceleration: Acceleration): Promise<void> {
    const token = UserStorage.getToken();
    if (acceleration.id) {
      await this.update(acceleration); 
    } else {
      await AccelerationRepository.add(acceleration, token);
    }
  }

  public static async update(acceleration: Acceleration): Promise<void> {
    const token = UserStorage.getToken();
    await AccelerationRepository.update(acceleration, token);
  }

  public static async delete(id: number): Promise<void> {
    const token = UserStorage.getToken();
    await AccelerationRepository.delete(id, token);
}
}
