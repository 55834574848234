const theme = {
  color: {
    primary: process.env.REACT_APP_THEME_COLOR_PRIMARY || "#FF8E00",
    secondary: process.env.REACT_APP_THEME_COLOR_SECONDARY || "#00D4B2",
    tertiary: process.env.REACT_APP_THEME_COLOR_TERTIARY || "#070604",
    quaternary: process.env.REACT_APP_THEME_COLOR_QUATERNARY || "#8C02D9",
    quinary: process.env.REACT_APP_THEME_COLOR_QUINARY || "#ffffff",
    senary: process.env.REACT_APP_THEME_COLOR_SENARY || "rgb(255, 170, 120)",
    septenary: process.env.REACT_APP_THEME_COLOR_SEPTENARY || "#ffa536",
    octonary: process.env.REACT_APP_THEME_COLOR_OCTONARY || "#4de2c9",
    nonary: process.env.REACT_APP_THEME_COLOR_NONARY || "rgb(230, 230, 230)",
    denary: process.env.REACT_APP_THEME_COLOR_DENARY || "#0706049f",
    undenary: process.env.REACT_APP_THEME_COLOR_UNDENARY || "#97e2d6",
    duodenary: process.env.REACT_APP_THEME_COLOR_DUODENARY || "#c690e3",
    tredenary: process.env.REACT_APP_THEME_COLOR_TREDENARY || "#a639e1",
    //quattuordenary: process.env.REACT_APP_THEME_COLOR_QUATTUORDENARY || "",
  },
  logo: {
    url: process.env.REACT_APP_THEME_LOGO_URL || "/images/logo-validese-white.png",
  },
  banner: {
    url: process.env.REACT_APP_THEME_LOGO_URL || "/public/images/homepage/banner-validese.jpg",
  },
  company: {
    name: process.env.REACT_APP_THEME_COMPANY_NAME || "Valide.se",
  },
}

export default theme