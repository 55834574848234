import { Answer, SearchParams, SearchResult } from "../../entities";
import { AnswerRepository } from "../../repositories";
import UserStorage from "../../util/userStorage";

export class AnswerService {
    public static async search(params: SearchParams): Promise<SearchResult<Answer>> {
        const token = UserStorage.getToken()
        const result = AnswerRepository.search(params, token)
        return result
    }

    public static async getByAccelerationId(accelerationId: string): Promise<Answer[]> {
        const token = UserStorage.getToken()
        return AnswerRepository.getByAccelerationId(accelerationId, token)
    }

    public static async add(answer: Answer): Promise<void> {
        const token = UserStorage.getToken()
        await AnswerRepository.add(answer, token)
    }

    public static async update(answer: Answer): Promise<void> {
        const token = UserStorage.getToken()
        await AnswerRepository.update(answer, token)
    }

    public static async upload(id: string, type: string, data: string): Promise<string> {
        const token = UserStorage.getToken()
        return await AnswerRepository.upload(id, type, data, token)
    }
}