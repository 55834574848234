import React from "react"
import { CgShapeCircle } from "react-icons/cg"

import "./AboutUs.scss"

export default function AboutUs() {
  return (
    <section id="about-us" className="about-us-container">
      <div className="description">
        <h2>
          <CgShapeCircle /> Sobre a Valide.se
        </h2>
        <div className="text">
          <span>
            Somos um ecossistema de apoio ao empreendedorismo, inovação,
            criatividade e inspiração, que, através da distribuição de uma
            metodologia proprietária em um sistema de licenciamento, auxilia
            consultorias de todo o país a tornarem-se Aceleradoras de Startups.
          </span>
        </div>
      </div>
      <div className="steps">
        <ul>
          <li>1. Metodologia</li>
          <li>2. Startup</li>
          <li>3. Aceleração</li>
          <li>4. Escala</li>
        </ul>
      </div>
    </section>
  )
}
