import React, { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap';
import classnames from 'classnames';
import TabPanelItem from './TabPanelItem/TabPanelItem';
import './TabPanel.scss';

interface TabPanelProps {
  children: React.ReactElement[] | React.ReactElement
  activeTab?: number
  setActiveTab?: any
}

export default function TabPanel(props: TabPanelProps) {
  const children = Array.isArray(props.children) ? props.children : [props.children]
  
  const [activeTab, setActiveTab] = useState(props.activeTab || 0);  

  useEffect(() => {
    if (props.activeTab !== undefined) toggle(props.activeTab)
    // eslint-disable-next-line
  }, [props.activeTab])

  const toggle = (tab: number) => {
    if (activeTab !== tab) setActiveTab(tab);
    if (props.setActiveTab) props.setActiveTab(tab)
  } 

  return (
    <section id="tab-panel-container">
      <Nav tabs>
        {children.map((item, index) => (
          <NavItem key={index}>
            <NavLink
              className={classnames({
                active: activeTab === index
              })}
              onClick={() => { toggle(index); }}
            >
              {item.props.tabName} 
            </NavLink>
          </NavItem>
        ))}   
      </Nav>
      <TabContent className="tab-panel-content" activeTab={activeTab}>
        {children.map((item, index) => (
          <TabPanelItem {...item.props} index={index} key={index} />
        ))}
      </TabContent>
    </section>
  );
}