import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week3.scss';


export default function Phase3Week3(props: ExerciseProps) {  
  const [data, setData] = useState({
    estimatedRevenue1: props.answer.answer?.estimatedRevenue1 || '',
    estimatedFunding1: props.answer.answer?.estimatedFunding1 || '',
    estimatedCosts1: props.answer.answer?.estimatedCosts1 || '',
    estimatedExpenses1: props.answer.answer?.estimatedExpenses1 || '',

    estimatedRevenue2: props.answer.answer?.estimatedRevenue2 || '',
    estimatedFunding2: props.answer.answer?.estimatedFunding2 || '',
    estimatedCosts2: props.answer.answer?.estimatedCosts2 || '',
    estimatedExpenses2: props.answer.answer?.estimatedExpenses2 || '',

    estimatedRevenue3: props.answer.answer?.estimatedRevenue3 || '',
    estimatedFunding3: props.answer.answer?.estimatedFunding3 || '',
    estimatedCosts3: props.answer.answer?.estimatedCosts3 || '',
    estimatedExpenses3: props.answer.answer?.estimatedExpenses3 || '',

    estimatedRevenue4: props.answer.answer?.estimatedRevenue4 || '',
    estimatedFunding4: props.answer.answer?.estimatedFunding4 || '',
    estimatedCosts4: props.answer.answer?.estimatedCosts4 || '',
    estimatedExpenses4: props.answer.answer?.estimatedExpenses4 || '',

    estimatedRevenue5: props.answer.answer?.estimatedRevenue5 || '',
    estimatedFunding5: props.answer.answer?.estimatedFunding5 || '',
    estimatedCosts5: props.answer.answer?.estimatedCosts5 || '',
    estimatedExpenses5: props.answer.answer?.estimatedExpenses5 || '',

    estimatedValuation: props.answer.answer?.estimatedValuation || '',
    claimedCapital: props.answer.answer?.claimedCapital || '',
  })

  useEffect(() => {
    const isCompleted = data.estimatedRevenue1.length >= 1 && data.estimatedFunding1.length >= 1 && 
    data.estimatedCosts1.length >= 1 && data.estimatedExpenses1.length >= 1 && 
    data.estimatedRevenue2.length >= 1 && data.estimatedFunding2.length >= 1 && 
    data.estimatedCosts2.length >= 1 && data.estimatedExpenses2.length >= 1 && 
    data.estimatedRevenue3.length >= 1 && data.estimatedFunding3.length >= 1 && 
    data.estimatedCosts3.length >= 1 && data.estimatedExpenses3.length >= 1 && 
    data.estimatedRevenue4.length >= 1 && data.estimatedFunding4.length >= 1 && 
    data.estimatedCosts4.length >= 1 && data.estimatedExpenses4.length >= 1 && 
    data.estimatedRevenue5.length >= 1 && data.estimatedFunding5.length >= 1 && 
    data.estimatedCosts5.length >= 1 && data.estimatedExpenses5.length >= 1 && 
    data.estimatedValuation.length >= 1 && data.claimedCapital.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Preencha corretamente para chegarmos a uma estimativa inicial de seu Valuation:
          </h2>
        </div>
        <form className="phase2-week3-form" action="">
          <Row>
            <Col md={6}>
              <Row><h3>ANO 1</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedRevenue1"
                      type="number"
                      required
                      value={data.estimatedRevenue1}
                      onChange={(event) => setData({...data, estimatedRevenue1: event.target.value})}
                    />
                    <Label for="estimatedRevenue1">
                      Faturamento Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedFunding1"
                      type="number"
                      required
                      value={data.estimatedFunding1}
                      onChange={(event) => setData({...data, estimatedFunding1: event.target.value})}
                    />
                    <Label for="estimatedFunding1">
                      Captações Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedCosts1"
                      type="number"
                      required
                      value={data.estimatedCosts1}
                      onChange={(event) => setData({...data, estimatedCosts1: event.target.value})}
                    />
                    <Label for="estimatedCosts1">
                      Custos Estimados
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedExpenses1"
                      type="number"
                      required
                      value={data.estimatedExpenses1}
                      onChange={(event) => setData({...data, estimatedExpenses1: event.target.value})}
                    />
                    <Label for="estimatedExpenses1">
                      Despesas Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row><h3>ANO 2</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedRevenue2"
                      type="number"
                      required
                      value={data.estimatedRevenue2}
                      onChange={(event) => setData({...data, estimatedRevenue2: event.target.value})}
                    />
                    <Label for="estimatedRevenue2">
                      Faturamento Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedFunding2"
                      type="number"
                      required
                      value={data.estimatedFunding2}
                      onChange={(event) => setData({...data, estimatedFunding2: event.target.value})}
                    />
                    <Label for="estimatedFunding2">
                      Captações Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedCosts2"
                      type="number"
                      required
                      value={data.estimatedCosts2}
                      onChange={(event) => setData({...data, estimatedCosts2: event.target.value})}
                    />
                    <Label for="estimatedCosts2">
                      Custos Estimados
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedExpenses2"
                      type="number"
                      required
                      value={data.estimatedExpenses2}
                      onChange={(event) => setData({...data, estimatedExpenses2: event.target.value})}
                    />
                    <Label for="estimatedExpenses2">
                      Despesas Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row><h3>ANO 3</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedRevenue3"
                      type="number"
                      required
                      value={data.estimatedRevenue3}
                      onChange={(event) => setData({...data, estimatedRevenue3: event.target.value})}
                    />
                    <Label for="estimatedRevenue3">
                      Faturamento Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedFunding3"
                      type="number"
                      required
                      value={data.estimatedFunding3}
                      onChange={(event) => setData({...data, estimatedFunding3: event.target.value})}
                    />
                    <Label for="estimatedFunding3">
                      Captações Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedCosts3"
                      type="number"
                      required
                      value={data.estimatedCosts3}
                      onChange={(event) => setData({...data, estimatedCosts3: event.target.value})}
                    />
                    <Label for="estimatedCosts3">
                      Custos Estimados
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedExpenses3"
                      type="number"
                      required
                      value={data.estimatedExpenses3}
                      onChange={(event) => setData({...data, estimatedExpenses3: event.target.value})}
                    />
                    <Label for="estimatedExpenses3">
                      Despesas Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row><h3>ANO 4</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedRevenue4"
                      type="number"
                      required
                      value={data.estimatedRevenue4}
                      onChange={(event) => setData({...data, estimatedRevenue4: event.target.value})}
                    />
                    <Label for="estimatedRevenue4">
                      Faturamento Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedFunding4"
                      type="number"
                      required
                      value={data.estimatedFunding4}
                      onChange={(event) => setData({...data, estimatedFunding4: event.target.value})}
                    />
                    <Label for="estimatedFunding4">
                      Captações Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedCosts4"
                      type="number"
                      required
                      value={data.estimatedCosts4}
                      onChange={(event) => setData({...data, estimatedCosts4: event.target.value})}
                    />
                    <Label for="estimatedCosts4">
                      Custos Estimados
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedExpenses4"
                      type="number"
                      required
                      value={data.estimatedExpenses4}
                      onChange={(event) => setData({...data, estimatedExpenses4: event.target.value})}
                    />
                    <Label for="estimatedExpenses4">
                      Despesas Estimadas
                    </Label>  
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row><h3>ANO 5</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedRevenue5"
                      type="number"
                      required
                      value={data.estimatedRevenue5}
                      onChange={(event) => setData({...data, estimatedRevenue5: event.target.value})}
                    />
                    <Label for="estimatedRevenue5">
                      Faturamento Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedFunding5"
                      type="number"
                      required
                      value={data.estimatedFunding5}
                      onChange={(event) => setData({...data, estimatedFunding5: event.target.value})}
                    />
                    <Label for="estimatedFunding5">
                      Captações Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedCosts5"
                      type="number"
                      required
                      value={data.estimatedCosts5}
                      onChange={(event) => setData({...data, estimatedCosts5: event.target.value})}
                    />
                    <Label for="estimatedCosts5">
                      Custos Estimados
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedExpenses5"
                      type="number"
                      required
                      value={data.estimatedExpenses5}
                      onChange={(event) => setData({...data, estimatedExpenses5: event.target.value})}
                    />
                    <Label for="estimatedExpenses5">
                      Despesas Estimadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row><h3 className="hidden-subtitle">-</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="estimatedValuation"
                      type="number"
                      required
                      value={data.estimatedValuation}
                      onChange={(event) => setData({...data, estimatedValuation: event.target.value})}
                    />
                    <Label for="estimatedValuation">
                      Valuation Estimado
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="claimedCapital"
                      type="number"
                      required
                      value={data.claimedCapital}
                      onChange={(event) => setData({...data, claimedCapital: event.target.value})}
                    />
                    <Label for="claimedCapital">
                      Capital Pleiteado
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>    
          </Row>
        </form>
      </section>
    </div>
  )
}