import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout/Layout';
import { Button, Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';
import { User } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';
import { AuthenticationService } from '../../../../services/AuthenticationService';
import { UserService } from '../../../../services/UserService';

import '../../Dashboard.scss';


export default function MasterFormPage() {
  const [user, setUser] = useState(new User())

  useEffect(() => {
    load()
  }, [])

  const load = async () => {
    const userId = UserStorage.get()?.id
    if (!userId) return
    
    const authService = new AuthenticationService()
    const token = UserStorage.getToken()
    const user = await authService.getUserByToken(token)
    setUser(user)
  }

  const save = async () => {
    await UserService.save(user)
  }

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user)
    userNew.setAttributeValue(code, value)                    
    setUser(userNew)
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>Administrador Master</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Informações de acesso</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="name"
                  name="name"
                  placeholder="Digite o nome completo"
                  type="text"
                  required
                  value={user.getAttributeValue('name')}
                  onChange={(event) => changeFieldValue('name', event.currentTarget.value)}
                />
                <Label for="name">
                  Nome completo
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Digite o email"
                  type="email"
                  required
                  value={user.email}
                  onChange={(event) => setUser(new User({...user, email: event.currentTarget.value}))}
                />
                <Label for="email">
                  Email
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={save} type="submit"
          >
            Cadastrar
          </Button>
        </Form>
      </section>
    </Layout>
  );
}