import { Button, Flex, useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { GrEdit, GrView, GrTrash } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import DataTable from "../../../../components/dataTable/DataTable";
import Layout from "../../../../components/layout/Layout";
import {
  Acceleration,
  SearchParams,
  SearchResultInfo,
} from "../../../../entities";
import { AccelerationService } from "../../../../services";
import UserStorage from "../../../../util/userStorage";

export default function AccelerationListPage() {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [accelerations, setAccelerations] = useState<Acceleration[]>([]);
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo());

  useEffect(() => {
    load();
  }, []);

  const load = (page = 1) => {
    setLoading(true);

    const params = new SearchParams([], page, 20);
    AccelerationService.search(params)
      .then((result) => {
        setAccelerations(result.data);
        setResultInfo(result.info);
      })
      .finally(() => setLoading(false));
  };

  const deleteItem = (id: number) => {
    if (window.confirm("Tem certeza de que deseja excluir esta aceleração?")) {
      AccelerationService.delete(id)
        .then(() => {
          load();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const renderActions = (params: any) => {
    return (
      <div className="table-action-button-container">
        <Button onClick={() => navigate(`/acceleration/${params.id}`)}>
          <GrView />
        </Button>

        {UserStorage.get()?.role?.code === "licensed" && (
          <Button onClick={() => navigate(`form/${params.id}`)}>
            <GrEdit />
          </Button>
        )}
        {/* <Button
          onClick={() => deleteItem(params.id)}
          className="table-action-button"
        >
          <GrTrash />
        </Button> */}
      </div>
    );
  };

  const columns = [
    { field: "name", headerName: "Nome" },
    { headerName: "Ações", renderCell: renderActions },
  ];

  return (
    <Layout internal={true}>
      <Flex justifyContent="center" pt="2rem">
        <DataTable
          title="Ciclos de Aceleração"
          checkboxSelection={false}
          rows={accelerations}
          columns={columns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={loading}
          newLink={
            UserStorage.get()?.role?.code === "licensed"
              ? `/acceleration/form/0`
              : undefined
          }
        />
      </Flex>
    </Layout>
  );
}
