import React, { useState } from "react"
import { CgShapeCircle } from "react-icons/cg"

import "./Contact.scss"
import { SiteContact } from "../../entities"
import { SiteService } from "../../services/site/SiteService"
import Loader from "../loader/Loader"

import InputMask from "react-input-mask"
import { FormControl, Input, FormLabel } from "@chakra-ui/react"

import { useWhitelabel } from "../../stores/WhiteLabelStore"

export default function Contact() {
  const whitelabel = useWhitelabel().get()

  const [form, setForm] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  })
  const [sending, setSending] = useState(false)

  const save = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    if (form.name.length === 0) {
      alert("Digite o nome.")
      return
    }

    if (form.message.length === 0) {
      alert("Digite a mensagem.")
      return
    }

    setSending(true)
    try {
      const contact = new SiteContact(form)
      await SiteService.contact(contact)
    } catch (error) {
    } finally {
      setSending(false)
    }

    alert("Mensagem enviada com sucesso!")

    setForm({
      name: "",
      company: "",
      phone: "",
      email: "",
      message: "",
    })
    return
  }

  return (
    <section id="contact" className="contact-container">
      <h2>
        <CgShapeCircle /> Fale Conosco
      </h2>
      {sending && <Loader />}
      <form onSubmit={save}>
        <div className="contact-form-field">
          <label htmlFor="name">Seu nome</label>
          <input
            type="text"
            name="name"
            id="name"
            value={form.name}
            onChange={(event) => setForm({ ...form, name: event.target.value })}
          />
        </div>
        <FormControl w="auto" display="flex" flexDirection="column" gap="0">
          <FormLabel
            m="0"
            w="auto"
            maxWidth="130px"
            borderRadius="0.3rem 0.3rem 0 0"
            p="0.25rem 0.5rem"
            bg={whitelabel?.colors?.primary}
            color={whitelabel?.colors?.quinary}
            fontSize="1.1rem"
          >
            Seu telefone
          </FormLabel>
          <Input
            as={InputMask}
            mask="+(99) 99 99999-9999"
            maskChar={null}
            w="auto"
            maxWidth="584px"
            m="0"
            borderRadius="0 0.3rem 0.3rem 0.3rem"
            border={`2px solid ${whitelabel?.colors?.primary}`}
            focusBorderColor={whitelabel?.colors?.secondary}
            variant="outline"
            size="md"
            type="text"
            name="phone"
            id="phone"
            value={form.phone}
            onChange={(event) =>
              setForm({ ...form, phone: event.target.value })
            }
            _hover={{ border: "2px solid none" }}
          />
        </FormControl>
        <div className="contact-form-field">
          <label htmlFor="company">Sua empresa</label>
          <input
            type="text"
            name="company"
            id="company"
            value={form.company}
            onChange={(event) =>
              setForm({ ...form, company: event.target.value })
            }
          />
        </div>
        {/* <div className="contact-form-field">
          <label htmlFor="phone">Seu telefone</label>
          <input
            type="text"
            name="phone"
            id="phone"
            value={form.phone}
            onChange={(event) =>
              setForm({ ...form, phone: event.target.value })
            }
          />
        </div> */}
        <div className="contact-form-field">
          <label htmlFor="email">Seu email</label>
          <input
            type="email"
            name="email"
            id="email"
            value={form.email}
            onChange={(event) =>
              setForm({ ...form, email: event.target.value })
            }
          />
        </div>
        <div className="contact-form-field">
          <label htmlFor="message">Mensagem</label>
          <textarea
            name="message"
            id="message"
            rows={5}
            value={form.message}
            onChange={(event) =>
              setForm({ ...form, message: event.target.value })
            }
          ></textarea>
        </div>
        <div className="contact-form-button">
          <button type="submit">Enviar</button>
        </div>
      </form>
    </section>
  )
}
