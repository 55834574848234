import React from "react"
import { Flex, Text, Image } from "@chakra-ui/react"

import { useWhitelabel } from "../../../stores/WhiteLabelStore"

export default function DashboardLicensed() {
  const whitelabel = useWhitelabel().get()

  console.log(`Teste: ${whitelabel?.colors?.quinary}`)

  return (
    <Flex
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex w="100%" maxWidth="1440px" p="1rem" direction="column">
        <Text
          color={whitelabel?.colors?.quinary}
          fontSize={{ base: "1.5rem", sm: "2rem", md: "2rem", lg: "2rem" }}
          fontWeight="600"
          alignSelf="center"
          bg={whitelabel?.colors?.secondary}
          p="0.25rem 1.5rem"
          borderRadius="0.5rem"
        >
          Dashboard do Licenciado
        </Text>
        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Volumes de Startups
          </Text>
          <Flex
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            flexWrap="wrap"
            gap="1rem"
          >
            <Flex
              direction="column"
              maxW="180px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Programas Executados
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                12
              </Text>
            </Flex>
            <Flex
              direction="column"
              maxW="180px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startups Ingressantes
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                200
              </Text>
            </Flex>
            <Flex
              direction="column"
              maxW="180px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startups Concluídas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                150
              </Text>
            </Flex>
            <Flex
              direction="column"
              maxW="180px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Programas m Execução
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                30
              </Text>
            </Flex>
            <Flex
              direction="column"
              maxW="180px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startups em Execução
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                15
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Carteiras
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Valor Total de Carteiras Ativas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                5
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Programas em Execuções Iniciais
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                5
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Programas em Execuções Atuais
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                10
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Valores de Saída Executadas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                0
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Dados de Startups
          </Text>
          <Flex
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            flexWrap="wrap"
          >
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup A
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup B
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup C
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup D
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup E
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup F
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup G
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Outros Cadastros
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Mentores de sua Rede
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                35
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Fornecedores de Sua Rede
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                127
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Corporações de Sua Rede
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                150
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="225px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Investidores de Sua Rede
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                89
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Receitas Totais Obtidas
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Saídas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 100.500,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Vendas de Cotas das Carteiras
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 1.700.500,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Vendas de Cotas de Patrocínio
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 877.550,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Programas de Aceleração Corporativas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 1.177.890,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Comissionamento de Fornecedores
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 487.310,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Workshops
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 332.820,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Negócios na Rede
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 777.460,00
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
