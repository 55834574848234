import { Week } from "./Week"

export class Phase {
  private _id: string
    public id: string|undefined
    public name: string
    public sort: number = 0
    public weeks: Week[] = []
  
  public constructor(data: any) {
    this._id = data.id || data._id
    this.id = data.id
            
    this.name = data.name
            
    this.sort = data.sort
            
    if (data.weeks) {
      for (const week of data.weeks) {
        this.weeks.push(new Week(week ))
      }
    }
          
  }
}