import { Exercise } from "./Exercise"

export class Week {
  private _id: string
      public id: string|undefined
      public name: string
      public sort: number = 0
      public exercises: Exercise[] = []
  
  public constructor(data: any) {
    this._id = data.id || data._id
          
        this.id = data.id
                
        this.name = data.name
                
        this.sort = data.sort
                
        if (data.exercises) {
                      for (const exercise of data.exercises) {
              this.exercises.push(new Exercise(exercise))
            }
                  }
              
  }
}