const whitelabelIdKey = "@whitelabel";
const whitelabelConfigsKey = "@whitelabelConfigs";

export default class WhiteLabelStorage { 
  static hasId = ():boolean => { 
    var id = localStorage.getItem(whitelabelIdKey);
    return !!id;
  };

  static getId = ():string => {
      const id = localStorage.getItem(whitelabelIdKey);
      return id || "";
  };

  static setId = (id:string) => {
    localStorage.setItem(whitelabelIdKey, id);
  };


  static hasConfigs = ():boolean => { 
    var configs = localStorage.getItem(whitelabelConfigsKey);
    return !!configs;
  };

  static getConfigs = ():any|null => {
    const configs = localStorage.getItem(whitelabelConfigsKey);
    if (!configs)
        return null;

    const data: any = JSON.parse(configs);
    return data ? data : null;
  };

  static setConfigs = (configs:any) => {
    const configsJson = JSON.stringify(configs)
    localStorage.setItem(whitelabelConfigsKey, configsJson);
  };
}