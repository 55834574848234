export class Permission {
  private _id: string
      public id: string|undefined
      public permission: string|undefined
      public code: string|undefined
      public group: string|undefined
  
  public constructor(data: any) {
    this._id = data.id || data._id
          
        this.id = data.id
                
        this.permission = data.permission
                
        this.code = data.code
                
        this.group = data.group
              
  }
}