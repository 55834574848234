import { User } from "../user"
import { Acceleration } from "./Acceleration"
import { Exercise } from "./Exercise"
import { Phase } from "./Phase"
import { Week } from "./Week"

export class Answer {
  private _id: string
  public id: string|undefined
  public acceleration: Acceleration
  public phase: Phase
  public week: Week
  public exercise: Exercise
  public startup: User
  public answer: any
  public isCompleted = false
  
  public constructor(data: any={}) {
    this._id = data.id || data._id          
    this.id = data.id || data._id  
            
    this.acceleration = new Acceleration(data.acceleration)
    this.phase = new Phase(data.phase)
    this.week = new Week (data.week)
    this.exercise = new Exercise(data.exercise)            
    this.startup = new User(data.startup)
            
    this.answer = data.answer            
    this.isCompleted = data.isCompleted
              
  }
}