import React from "react";
import { Route, Routes } from "react-router-dom";

import HomePage from "./home/HomePage";
import Login from "./login/Login";
import Password from "./password/Password";
import Register from "./register/Register";
import DashboardPage from "./dashboard/panel/DashboardPage";
import AccelerationPage from "./dashboard/acceleration/AccelerationPage";
import HelpCenterPage from "./help-center-page/HelpCenterPage";

import LicensedAdminProfilePage from "./dashboard/licensed/admin/form/LicensedAdminFormPage";
import StartupFormPage from "./dashboard/startup/form/StartupFormPage";
import FounderFormPage from "./dashboard/startup/founder/form/FounderFormPage";
import LicensedAdminListPage from "./dashboard/licensed/admin/list/LicensedAdminListPage";
import ContactFormPage from "./dashboard/contact/form/ContactFormPage";
import LicensedListPage from "./dashboard/licensed/list/LicensedListPage";
import LicensedFormPage from "./dashboard/licensed/form/LicensedFormPage";
import ContactListPage from "./dashboard/contact/list/ContactListPage";
import StartupListPage from "./dashboard/startup/list/StartupListPage";
import MasterFormPage from "./dashboard/master/form/MasterFormPage";
import ReportPage from "./report-page/ReportPage";
import ContactViewPage from "./dashboard/contact/view/ContactViewPage";

import ChangePassword from "./change-password/ChangePassword";
import DrivePage from "./drive/DrivePage";
import AccelerationListPage from "./dashboard/acceleration/list/AccelarationListPage";
import AccelerationFormPage from "./dashboard/acceleration/form/AccelerationFormPage";

const Pages = () => <Routes></Routes>;

const WrappedRoutes = () => {
  return (
    <div>
      <div className="container__wrap">
        <Pages />
      </div>
    </div>
  );
};

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/recuperar-senha" element={<Password />} />
      <Route path="/cadastrar" element={<Register />} />
      <Route path="/perfil/alterar-senha" element={<ChangePassword />} />

      <Route path="/painel" element={<DashboardPage />} />
      <Route path="/acceleration" element={<AccelerationListPage />} />
      <Route path="/acceleration/form/:id" element={<AccelerationFormPage />} />
      <Route path="/acceleration/:id" element={<AccelerationPage />} />
      <Route path="/central-de-ajuda" element={<HelpCenterPage />} />

      <Route path="/master/:id" element={<MasterFormPage />} />

      <Route path="/licenciado/admin" element={<LicensedAdminListPage />} />
      <Route
        path="/licenciado/admin/:id"
        element={<LicensedAdminProfilePage />}
      />

      <Route path="/licenciado" element={<LicensedListPage />} />
      <Route path="/licenciado/:id" element={<LicensedFormPage />} />

      <Route path="/startup" element={<StartupListPage />} />
      <Route path="/startup/:id" element={<StartupFormPage />} />

      <Route path="/startup/fundador/:id" element={<FounderFormPage />} />

      <Route path="/mentor" element={<ContactListPage />} />
      <Route path="/mentor/view/:id" element={<ContactViewPage />} />
      <Route path="/mentor/:id" element={<ContactFormPage />} />

      <Route path="/investidor" element={<ContactListPage />} />
      <Route path="/investidor/:id" element={<ContactFormPage />} />

      <Route path="/report" element={<ReportPage />} />

      <Route path="/drive" element={<DrivePage />} />

      <Route path="*" element={<WrappedRoutes />} />
    </Routes>
  );
};

export default Router;
