import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week6.scss';


export default function Phase3Week6(props: ExerciseProps) {

  const [data, setData] = useState({
    sellGoal: props.answer.answer?.sellGoal || '',
    uniqueSalesVolume: props.answer.answer?.uniqueSalesVolume || '',
    moneyVolume: props.answer.answer?.moneyVolume || '',
  })

  useEffect(() => {
    const isCompleted = data.sellGoal.length >= 1 

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase3-week6-form" action="">
          <Row>
            <Col className="col-subtitle" md={6}>
              <h3>Resultado da meta de vendas da Fase 2 - Semana 4:</h3>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={4}>
                  <button
                    type="button"
                    onClick={(event) => setData({...data, sellGoal: 'below'})}
                    className={`${data.sellGoal === 'below' ? 'red-button' : 'not-active'}`}
                  >
                    Abaixo
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    onClick={(event) => setData({...data, sellGoal: 'exact'})}
                    className={`${data.sellGoal === 'exact' ? 'red-button' : 'not-active'}`}
                  >
                    Exata
                  </button>
                </Col>
                <Col md={4}>
                  <button
                    type="button"
                    onClick={(event) => setData({...data, sellGoal: 'above'})}
                    className={`${data.sellGoal === 'above' ? 'red-button' : 'not-active'}`}
                  >
                    Acima
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="uniqueSalesVolume"
                  name="uniqueSalesVolume"
                  type="number"
                  required
                  value={data.uniqueSalesVolume}
                  onChange={(event) => setData({...data, uniqueSalesVolume: event.target.value})}
                />
                <Label for="uniqueSalesVolume">
                  Valor em Vendas Únicas
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="moneyVolume"
                  name="moneyVolume"
                  type="number"
                  required
                  value={data.moneyVolume}
                  onChange={(event) => setData({...data, moneyVolume: event.target.value})}
                />
                <Label for="moneyVolume">
                  Volume em R$
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}