import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { Answer } from '../../../entities';
import { ExerciseProps } from '../base';

import './MvpCharacteristics.scss';

export default function MvpCharacteristics(props: ExerciseProps) { 
  const [data, setData] = useState({
    name: props.answer.answer?.name || '',
    type: props.answer.answer?.type || '',
    solution: props.answer.answer?.solution || '',
    function1: props.answer.answer?.function1 || '',
    function2: props.answer.answer?.function2 || '',
    function3: props.answer.answer?.function3 || '',
    businessModel: props.answer.answer?.businessModel || '',
  })

  useEffect(() => {
    const isCompleted = false
    const answer = new Answer({
      ...props.answer, 
      answer: data,
      isCompleted
    })
    
    props.save(answer)
    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>FASE 1 - Semana 8</h1>
          <h2>Adicione as características finais de seu MVP:</h2>
        </div>
        <form className="mvp-characteristics-form" action="">
          <Row>
            <Col md={6}>
              <div>
                <label htmlFor="name">O/A</label>
                <input 
                  type="text" 
                  id="name"
                  name="name"
                  placeholder="Insira o nome da Startup" 
                  value={data.name}
                  onChange={(event) => setData({...data, name: event.target.value})}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="type">é um/uma</label>
                <input 
                  type="text" 
                  name="type" 
                  id="type" 
                  placeholder="site / plataforma / app / aparelho" 
                  value={data.type}
                  onChange={(event) => setData({...data, type: event.target.value})}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <label htmlFor="solution">que</label>
                <input 
                  type="text" 
                  name="solution"
                  id="solution" 
                  placeholder="descreva a solução de um problema" 
                  value={data.solution}
                  onChange={(event) => 
                  setData({...data, solution: event.target.value})}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="function1">através de</label>
                <input 
                  type="text" 
                  name="function1" 
                  id="function1" 
                  placeholder="Função 1"
                  value={data.function1}
                  onChange={(event) => setData({...data, function1: event.target.value})}
                />
              </div>
            </Col>          
          </Row>
          <Row>
            <Col md={6}>
              <div>
                <label htmlFor="function2">, de</label>
                <input 
                  type="text" 
                  name="function2" 
                  id="function2" 
                  placeholder="Função 2" 
                  value={data.function2}
                  onChange={(event) => setData({...data, function2: event.target.value})}
                />
              </div>
            </Col>
            <Col md={6}>
              <div>
                <label htmlFor="function3">e de</label>
                <input 
                  type="text" 
                  name="function3" 
                  id="function3" 
                  placeholder="Função 3" 
                  value={data.function3}
                  onChange={(event) => setData({...data, function3: event.target.value})}
                />
              </div>
            </Col>          
          </Row>
          <Row>
            <Col md={12}>
              <div>
                <label htmlFor="businessModel">num/numa</label>
                <input 
                  type="text" 
                  name="businessModel" 
                  id="businessModel" 
                  placeholder="Modelo de negócio" 
                  value={data.businessModel}
                  onChange={(event) => setData({...data, businessModel: event.target.value})}
                />
              </div>
            </Col>
          </Row>      
        </form>
      </section>
    </div>
  )
}