import urlJoin from 'url-join'
import { User } from '../entities/user/User'
import ApiClient from './ApiClient'
import UserStorage from '../util/userStorage'
import { UserRepository } from '../repositories/user/UserRepository'
import { SearchParams, SearchResult } from '../entities'

const apiClient = new ApiClient()
export class UserService {

  public static async search(params: SearchParams): Promise<SearchResult<User>> {
    const token = UserStorage.getToken()
    return await UserRepository.serch(params, token)
  }

  public static async get(id: string): Promise<User> {
    const token = UserStorage.getToken()
    return UserRepository.get(id, token)
  }


  public static async save(user: User): Promise<void> {
    const token = UserStorage.getToken()
    if (user.id) {
      await UserRepository.update(user, token)
    } else {
      await UserRepository.add(user, token)
    }
  }

  public async delete(user: User, token: string): Promise<void> {
    const url = urlJoin(apiClient.getRestDefaultUri(), `/user`)

    try {
      await apiClient.DoRequest('DELETE', url, user, { Authorization: `Bearer ${token}` })
    } catch (error: any) {
      if (error?.response?.data?.messages) {
        throw new Error(error.response.data.messages[0].message)
      } else {
        throw error
      }
    }
  }

  public static async getFounders(id: string): Promise<User[]> {
    const token = UserStorage.getToken()
    return await UserRepository.getFounders(id, token)
  }
}