import { Role } from "./Role";
import { UserAttribute } from "./UserAttribute";

export class User {
  private _id: string;
  public id: string;
  public role: Role | undefined;
  public name: string;
  public email: string;
  public password: string;
  public document: string;
  public attributes: UserAttribute[] = [];
  public founders: User[] = [];
  public startup: User | undefined = undefined;
  public licensed: User | undefined = undefined;

  public constructor(data: any = {}) {
    this._id = data.id || data._id;
    this.id = data.id;

    if (data.role) {
      this.role = new Role(data.role);
    }

    this.name = data.name || "";
    this.email = data.email || "";
    this.password = data.password || "";
    this.document = data.document || "";

    if (data.attributes) {
      for (const attr of data.attributes) {
        this.attributes.push(new UserAttribute(attr));
      }
    }

    if (data.founders) {
      for (const founder of data.founders) {
        this.founders.push(founder);
      }
    }

    if (data.startup) {
      this.startup = new User(data.startup);
    }

    if (data.licensed) {
      this.licensed = new User(data.licensed);
    }
  }

  public getAttributeValue(code: string) {
    for (const attribute of this.attributes) {
      if (attribute.code === code) {
        return attribute.value;
      }
    }

    return "";
  }

  public setAttributeValue(code: string, value: any) {
    for (const attribute of this.attributes) {
      if (attribute.code === code) {
        attribute.value = value;
        return;
      }
    }
    this.attributes.push(new UserAttribute({ code, value }));
  }

  public getAttributeValueFromArray(code: string, key: string) {
    const arr = this.getAttributeValue(code);

    if (Array.isArray(arr)) {
      const item = arr.find((item) => item.key === key);
      if (item) {
        return item.value;
      }
    }

    return "";
  }

  public addUpdateAttributeValueFromArray(
    code: string,
    key: string,
    value: any
  ) {
    const arr = this.getAttributeValue(code);

    if (Array.isArray(arr)) {
      const idx = arr.findIndex((item) => item.key === key);
      if (idx >= 0) {
        arr[idx] = { key, value };
      } else {
        arr.push({ key, value });
      }
      this.setAttributeValue(code, arr);
    } else {
      this.setAttributeValue(code, [{ key, value }]);
    }
  }

  public deleteAttributeValueFromArray(code: string, key: string) {
    const arr = this.getAttributeValue(code);

    if (Array.isArray(arr)) {
      const idx = arr.findIndex((item) => item.key === key);
      if (idx >= 0) {
        arr.splice(idx, 1);
        this.setAttributeValue(code, arr);
      }
    }
  }
}
