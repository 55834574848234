import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase2Week8.scss';


export default function Phase2Week8(props: ExerciseProps) {
  const [data, setData] = useState({
    newLeadsNumber: props.answer.answer?.newLeadsNumber || '',
    newReunionNumber: props.answer.answer?.newReunionNumber || '',
    remainingReunionNumber6: props.answer.answer?.remainingReunionNumber6 || '',
    remainingReunionNumber7: props.answer.answer?.remainingReunionNumber7 || '',

    newAcquisitionNumber: props.answer.answer?.newAcquisitionNumber || '',
    remainingAcquisitionNumber6: props.answer.answer?.remainingAcquisitionNumber6 || '',
    remainingAcquisitionNumber7: props.answer.answer?.remainingAcquisitionNumber7 || '',
    withdrawalsNumber: props.answer.answer?.withdrawalsNumber || '',
  })

  useEffect(() => {
    const isCompleted = data.newLeadsNumber.length >= 1 && data.newReunionNumber.length >= 1 && 
    data.remainingReunionNumber6.length >= 1 && data.remainingReunionNumber7.length >= 1 && data.newAcquisitionNumber.length >= 1 && 
    data.remainingAcquisitionNumber6.length >= 1 && data.remainingAcquisitionNumber7.length >= 1 && data.withdrawalsNumber.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase2-week8-form" action="">
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="newLeadsNumber"
                  name="newLeadsNumber"
                  type="number"
                  required
                  value={data.newLeadsNumber}
                  onChange={(event) => setData({...data, newLeadsNumber: event.target.value})}
                />
                <Label for="newLeadsNumber">
                  Número de Novos Leads
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="newReunionNumber"
                  name="newReunionNumber"
                  type="number"
                  required
                  value={data.newReunionNumber}
                  onChange={(event) => setData({...data, newReunionNumber: event.target.value})}
                />
                <Label for="newReunionNumber">
                  Número de Reuniões (dos novos leads)
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="remainingReunionNumber6"
                  name="remainingReunionNumber6"
                  type="number"
                  required
                  value={data.remainingReunionNumber6}
                  onChange={(event) => setData({...data, remainingReunionNumber6: event.target.value})}
                />
                <Label for="remainingReunionNumber6">
                  Número de Reuniões (remanescentes da semana 6)
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="remainingReunionNumber7"
                  name="remainingReunionNumber7"
                  type="number"
                  required
                  value={data.remainingReunionNumber7}
                  onChange={(event) => setData({...data, remainingReunionNumber7: event.target.value})}
                />
                <Label for="remainingReunionNumber7">
                  Número de Reuniões (remanescentes da semana 7)
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="newAcquisitionNumber"
                  name="newAcquisitionNumber"
                  type="number"
                  required
                  value={data.newAcquisitionNumber}
                  onChange={(event) => setData({...data, newAcquisitionNumber: event.target.value})}
                />
                <Label for="newAcquisitionNumber">
                  Número de Aquisições (dos novos leads)
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="remainingAcquisitionNumber6"
                  name="remainingAcquisitionNumber6"
                  type="number"
                  required
                  value={data.remainingAcquisitionNumber6}
                  onChange={(event) => setData({...data, remainingAcquisitionNumber6: event.target.value})}
                />
                <Label for="remainingAcquisitionNumber6">
                  Número de Aquisições (remanescentes da semana 6)
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="remainingAcquisitionNumber7"
                  name="remainingAcquisitionNumber7"
                  type="number"
                  required
                  value={data.remainingAcquisitionNumber7}
                  onChange={(event) => setData({...data, remainingAcquisitionNumber7: event.target.value})}
                />
                <Label for="remainingAcquisitionNumber7">
                  Número de Aquisições (remanescentes da semana 7)
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="withdrawalsNumber"
                  name="withdrawalsNumber"
                  type="number"
                  required
                  value={data.withdrawalsNumber}
                  onChange={(event) => setData({...data, withdrawalsNumber: event.target.value})}
                />
                <Label for="withdrawalsNumber">
                  Número de Desistências
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}