import React, { useState } from "react"
import { Link as ReactRouterLink } from "react-router-dom"
import Layout from "../../components/layout/Layout"
import DriveTable from "../../components/driveTable/DriveTable"
import { Button, Link as ChakraLink } from "@chakra-ui/react"
import { HiFolderOpen, HiDocumentText } from "react-icons/hi"
import { GrEdit, GrTrash, GrView } from "react-icons/gr"
import { useNavigate } from "react-router-dom"
import { SearchResultInfo } from "../../entities"
import UserStorage from "../../util/userStorage"

export default function DrivePage() {
  const navigate = useNavigate()
  const user = UserStorage.get()

  const [page, setPage] = useState(1)

  const deleteItem = async (id: string) => {
    if (window.confirm("Deseja realmente remover este item?") === false) return
    // setLoading(true)
    // ProductService.delete(id)
    //   .then((success: boolean) => {
    //     load(resultInfo.page)
    //   })
    //   .finally(() => setLoading(false))
  }

  const renderLink = (params: any) => {
    return (
      <ChakraLink
        w="auto"
        as={ReactRouterLink}
        target="_blank"
        to={params.link}
      >
        {params.title}
      </ChakraLink>
    )
  }

  const renderIcons = (params: any) => {
    return (
      // <>
      //   <HiFolderOpen className="drive-table-icon" />
      // </>
      <>
        <HiDocumentText className="drive-table-icon" />
      </>
    )
  }

  const renderActions = (params: any) => {
    console.log("params", params)
    return (
      <div className="drive-table-action-button-container">
        <ChakraLink
          w="auto"
          as={ReactRouterLink}
          target="_blank"
          to={params.link}
        >
          <Button onClick={() => navigate(`/drive`)}>
            <GrView />
          </Button>
        </ChakraLink>
        {user?.role?.code === "admin" && (
          <>
            <Button onClick={() => navigate(`/drive`)}>
              <GrEdit />
            </Button>
            <Button onClick={() => deleteItem(params.id)}>
              <GrTrash />
            </Button>
          </>
        )}
      </div>
    )
  }

  const columns = [
    //{ field: "id", headerName: "ID" },
    { headerName: "Tipo", renderCell: renderIcons },
    { headerName: "Nome", renderCell: renderLink },
    //{ field: "name", headerName: "Nome" },
    { field: "owner", headerName: "Proprietário" },
    { headerName: "Ações", renderCell: renderActions },
  ]

  const rows = [
    {
      id: 1,
      owner: "Renan Baradel",
      title: "Manual Programa de Aceleração (Apoio)",
      link: "https://docs.google.com/document/d/1DWinQwdcO1knBQK_xuZqa5-YtwBgDKqf7s-vCxdV-ZY/edit#heading=h.pnddfhpa9gwu",
    },
  ]

  return (
    <Layout internal={true}>
      <DriveTable
        title="Arquivos"
        caption=""
        rows={rows}
        columns={columns}
        checkboxSelection={false}
        pagination={
          new SearchResultInfo({
            page: page,
            pages: 10,
            pageSize: 10,
            count: 97,
          })
        }
        onPageChange={(page: number) => setPage(page)}
      />
    </Layout>
  )
}
