import React, { useState, useEffect, useCallback } from "react"
import { Flex, Text, HStack } from "@chakra-ui/react"
import { useWhitelabel } from "../../stores/WhiteLabelStore"
import "./DynamicText.scss"

export default function DynamicText() {
  const whitelabel = useWhitelabel().get()

  const initialText1 = ["Uma", "Uma", "Um", "Uma"]
  const initialText2 = ["metodologia", "plataforma", "ecossistema", "licença"]
  const initialText3 = [
    "auxiliar hubs de aceleração",
    "validar startups",
    "conectar investidores",
    "inovar corporações",
  ]

  const [newText1, setNewText1] = useState("Uma")
  const [newText2, setNewText2] = useState("metodologia")
  const [newText3, setNewText3] = useState("auxiliar hubs de aceleração")

  const shuffle = useCallback(() => {
    const idx = Math.floor(Math.random() * initialText1.length)
    setNewText1(initialText1[idx])
    setNewText2(initialText2[idx])
    setNewText3(initialText3[idx])
  }, [])

  useEffect(() => {
    const intervalID = setInterval(shuffle, 6000)
    return () => clearInterval(intervalID)
  }, [shuffle])

  return (
    <Flex
      maxWidth="100%"
      w={{ base: "100%", sm: "100%", md: "100%", lg: "auto" }}
      py="2rem"
      px="2rem"
      bg={whitelabel?.colors?.denary}
      color={whitelabel?.colors?.quinary}
      borderRadius={{ md: "0 0 0 0", lg: "0 2rem 2rem 0" }}
    >
      <Flex maxWidth="100%" alignItems="center">
        <Text
          mb="0"
          maxWidth="100%"
          className="dynamic-text-animation"
          fontWeight="600"
          fontSize={{ base: "1rem", sm: "1.25rem", md: "2rem", lg: "2.5rem" }}
        >
          {newText1} {newText2} para {newText3}
        </Text>
      </Flex>
    </Flex>
  )
}
