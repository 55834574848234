import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../../../components/layout/Layout';
import { Col, Row } from 'reactstrap';
import { BusinessCategory, SearchParams, User } from '../../../../entities';
import UserStorage from '../../../../util/userStorage';
import { UserService } from '../../../../services/UserService';

import '../../Dashboard.scss';
import { BusinessCategoryService } from '../../../../services/startup/BusinessCategoryService';

export default function ContactViewPage() {
  const navigate = useNavigate()
  const params = useParams()

  const [user, setUser] = useState(new User())
  const [businessCategories, setBusinessCategories] = useState<BusinessCategory[]>([])
  const [typeLabel, setTypeLabel] = useState('')

  useEffect(() => {
    loadBusinessCategories()

    let type = ''
    if (window.location.href.includes('/mentor') === true) {
      type = 'mentor'
      setTypeLabel('mentor')
    } else if (window.location.href.includes('/investidor') === true) {
      type = 'investor'
      setTypeLabel('investidor')
    }

    if (params?.id === 'me') {
      const user = UserStorage.get()
      if (user?.role?.code !== type) return 
      if (!user?.id) return
      load(user.id, type)
    } else if (params?.id !== '0') {
      if (!params.id) return
      load(params.id, type)  
    }
  }, [params])

  const load = async (id: string, type: string) => {    
    const dbUser = await UserService.get(id)
    if (dbUser?.role) {
      dbUser.role.code = type
    }
    setUser(dbUser)
  }

  const loadBusinessCategories = async () => {
    const params = new SearchParams([], 1, 9999)
    const result = await BusinessCategoryService.search(params)

    setBusinessCategories(result.data)
  }

  const businessCategoryName = (category: any) => {
    const businessCategory = businessCategories.find(item => item.id === category.key)
    if (businessCategory)
      return businessCategory.name
    return ''
  }

  const businessCategoryLevel = (category: any) => {
    const level = parseInt(category.value)
    if (level === 1) 
      return 'Pouco'
    if (level === 2) 
      return 'Médio'
    if (level === 3) 
      return 'Muito'
    return ''
  }
  
  const changeClassName = (op: string) => {
    if (op === "Pouco") return "low-test"
    if (op === "Médio") return "medium-test"
    if (op === "Muito") return "high-test"
    else return "no-test"
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Row>
          <Col md={12}>
            <div className="title">
              <h1>Informações de {typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1)}</h1>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Nome</h2>
            </div>
          </Col>
        </Row>
        <Row className="view-row-container">
          <Col md={3} className="test2">
            <span>Nome X</span>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Cases de Startup</h2>
            </div>
          </Col>
        </Row>
        {Array.isArray(user.getAttributeValue('startupCases')) && user.getAttributeValue('startupCases').map((item: any) => (
        <Row className="view-row-container">
          <Col md={2} className="test1">
            <span>{item.value.startup}</span>
          </Col>
          <Col md={8} className="test1">
            <span>{item.value.description}</span>
          </Col>
        </Row>
        ))}
        {/* <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Avaliação do Mentor</h2>
            </div>
          </Col>
        </Row>
        <Row className="view-row-container">
          <Col md={3} className="test2">
            <span>Avaliação X</span>
          </Col>
        </Row> */}
        <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Estado</h2>
            </div>
          </Col>
        </Row>
        <Row className="view-row-container">
          <Col md={3} className="test1">
            <span>São Paulo</span>
          </Col>
        </Row>
        {/* <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Startups Atendidas</h2>
            </div>
          </Col>
        </Row>
        <Row className="view-row-container">
          <Col md={3} className="test2">
            <span>Startup 1</span>
          </Col>
          <Col md={3} className="test2">
            <span>Startup 2</span>
          </Col>
          <Col md={3} className="test2">
            <span>Startup 3</span>
          </Col>
          <Col md={3} className="test2">
            <span>Startup 4</span>
          </Col>
          <Col md={3} className="test2">
            <span>Startup 5</span>
          </Col>
        </Row> */}
        {/* <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Mentorias / Horas Executadas</h2>
            </div>
          </Col>
        </Row>
        <Row className="view-row-container">
          <Col md={3} className="test1">
            <span>378 Horas</span>
          </Col>
        </Row> */}
        <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Modelos de Negócio</h2>
            </div>
          </Col>        
        </Row>
        <Row className="view-row-container">
          {Array.isArray(user.getAttributeValue('businessModel')) && user.getAttributeValue('businessModel')?.map((item: any) => (
            <Col md={1} className="business-model" key={item.key}><span>{item.key}</span></Col>
          ))}  
        </Row>
        <Row>
          <Col md={12}>
            <div className="sub-title">
              <h2>Verticais</h2>
            </div>
          </Col>
        </Row>
        <Row>
        {Array.isArray(user.getAttributeValue('businessCategories')) && user.getAttributeValue('businessCategories')?.map((category: any) => (
            // <Col md={2} key={category.key}>
            //   {businessCategoryName(category)}: {businessCategoryLevel(category)}
            // </Col>
            <Col md={2} className="business-categories" key={category.key}>
              <span>{businessCategoryName(category)}:</span>
              <span className={changeClassName(businessCategoryLevel(category))}>{businessCategoryLevel(category)}</span>
            </Col>
        ))}  
        </Row>
      </section>
    </Layout>
  )
}