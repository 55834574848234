import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase2Week3.scss';


export default function Phase2Week3(props: ExerciseProps) {
  const [data, setData] = useState({
    acquisitionQuestion1: props.answer.answer?.acquisitionQuestion1 || '',
    acquisitionQuestion2: props.answer.answer?.acquisitionQuestion2 || '',
    acquisitionAnswer1: props.answer.answer?.acquisitionAnswer1 || '',
    acquisitionAnswer2: props.answer.answer?.acquisitionAnswer2 || '',

    cancellationQuestion1: props.answer.answer?.cancellationQuestion1 || '',
    cancellationQuestion2: props.answer.answer?.cancellationQuestion2 || '',
    cancellationAnswer1: props.answer.answer?.cancellationAnswer1 || '',
    cancellationAnswer2: props.answer.answer?.cancellationAnswer2 || '',

    paymentQuestion1: props.answer.answer?.paymentQuestion1 || '',
    paymentQuestion2: props.answer.answer?.paymentQuestion2 || '',
    paymentAnswer1: props.answer.answer?.paymentAnswer1 || '',
    paymentAnswer2: props.answer.answer?.paymentAnswer2 || '',

    privacyQuestion1: props.answer.answer?.privacyQuestion1 || '',
    privacyQuestion2: props.answer.answer?.privacyQuestion2 || '',
    privacyAnswer1: props.answer.answer?.privacyAnswer1 || '',
    privacyAnswer2: props.answer.answer?.privacyAnswer2 || '',

    operationQuestion1: props.answer.answer?.operationQuestion1 || '',
    operationQuestion2: props.answer.answer?.operationQuestion2 || '',
    operationAnswer1: props.answer.answer?.operationAnswer1 || '',
    operationAnswer2: props.answer.answer?.operationAnswer2 || '',
  })

  useEffect(() => {
    const isCompleted = data.acquisitionQuestion1.length >= 1 && data.acquisitionQuestion2.length >= 1 && 
    data.acquisitionAnswer1.length >= 1 && data.acquisitionAnswer2.length >= 1 && data.cancellationQuestion1.length >= 1 && 
    data.cancellationQuestion2.length >= 1 && data.cancellationAnswer1.length >= 1 && data.cancellationAnswer2.length >= 1 && 
    data.paymentQuestion1.length >= 1 && data.paymentQuestion2.length >= 1 && data.paymentAnswer1.length >= 1 && 
    data.paymentAnswer2.length >= 1 && data.privacyQuestion1.length >= 1 && data.privacyQuestion2.length >= 1 && 
    data.privacyAnswer1.length >= 1 && data.privacyAnswer2.length >= 1 && data.operationQuestion1.length >= 1 && 
    data.operationQuestion2.length >= 1 && data.operationAnswer1.length >= 1 && data.operationAnswer2.length >= 1 

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Adicione as questões que serão introduzidas ao seu FAQ
          </h2>
        </div>
        <form className="phase2-week3-form" action="">
          <Row>
            <Col md={6}>
              <Row><h3>Questões de Aquisição:</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="acquisitionQuestion1"
                      type="text"
                      required
                      value={data.acquisitionQuestion1}
                      onChange={(event) => setData({...data, acquisitionQuestion1: event.target.value})}
                    />
                    <Label for="acquisitionQuestion1">
                      Descreva Questão 1
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="acquisitionAnswer1"
                      type="text"
                      required
                      value={data.acquisitionQuestion2}
                      onChange={(event) => setData({...data, acquisitionQuestion2: event.target.value})}
                    />
                    <Label for="acquisitionAnswer1">
                      Resposta Questão 1
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="acquisitionQuestion2"
                      type="text"
                      required
                      value={data.acquisitionAnswer1}
                      onChange={(event) => setData({...data, acquisitionAnswer1: event.target.value})}
                    />
                    <Label for="acquisitionQuestion2">
                      Descreva Questão 2
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="acquisitionAnswer1"
                      type="text"
                      required
                      value={data.acquisitionAnswer2}
                      onChange={(event) => setData({...data, acquisitionAnswer2: event.target.value})}
                    />
                    <Label for="acquisitionAnswer1">
                      Resposta Questão 2
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row><h3>Questões de Cancelamento:</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="cancellationQuestion1"
                      type="text"
                      required
                      value={data.cancellationQuestion1}
                      onChange={(event) => setData({...data, cancellationQuestion1: event.target.value})}
                    />
                    <Label for="cancellationQuestion1">
                      Descreva Questão 1
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="cancellationAnswer1"
                      type="text"
                      required
                      value={data.cancellationAnswer1}
                      onChange={(event) => setData({...data, cancellationAnswer1: event.target.value})}
                    />
                    <Label for="cancellationAnswer1">
                      Resposta Questão 1
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="cancellationQuestion2"
                      type="text"
                      required
                      value={data.cancellationQuestion2}
                      onChange={(event) => setData({...data, cancellationQuestion2: event.target.value})}
                    />
                    <Label for="cancellationQuestion2">
                      Descreva Questão 2
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="cancellationAnswer2"
                      type="text"
                      required
                      value={data.cancellationAnswer2}
                      onChange={(event) => setData({...data, cancellationAnswer2: event.target.value})}
                    />
                    <Label for="cancellationAnswer2">
                      Resposta Questão 2
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row><h3>Questões de Pagamento:</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="paymentQuestion1"
                      type="text"
                      required
                      value={data.paymentQuestion1}
                      onChange={(event) => setData({...data, paymentQuestion1: event.target.value})}
                    />
                    <Label for="paymentQuestion1">
                      Descreva Questão 1
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="paymentAnswer1"
                      type="text"
                      required
                      value={data.paymentAnswer1}
                      onChange={(event) => setData({...data, paymentAnswer1: event.target.value})}
                    />
                    <Label for="paymentAnswer1">
                      Resposta Questão 1
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="paymentQuestion2"
                      type="text"
                      required
                      value={data.paymentQuestion2}
                      onChange={(event) => setData({...data, paymentQuestion2: event.target.value})}
                    />
                    <Label for="paymentQuestion2">
                      Descreva Questão 2
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="paymentAnswer2"
                      type="text"
                      required
                      value={data.paymentAnswer2}
                      onChange={(event) => setData({...data, paymentAnswer2: event.target.value})}
                    />
                    <Label for="paymentAnswer2">
                      Resposta Questão 2
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row><h3>Questões de Privacidade:</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="privacyQuestion1"
                      type="text"
                      required
                      value={data.privacyQuestion1}
                      onChange={(event) => setData({...data, privacyQuestion1: event.target.value})}
                    />
                    <Label for="privacyQuestion1">
                      Descreva Questão 1
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="privacyAnswer"
                      type="text"
                      required
                      value={data.privacyAnswer1}
                      onChange={(event) => setData({...data, privacyAnswer1: event.target.value})}
                    />
                    <Label for="privacyAnswer">
                      Resposta Questão 1
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="privacyQuestion2"
                      type="text"
                      required
                      value={data.privacyQuestion2}
                      onChange={(event) => setData({...data, privacyQuestion2: event.target.value})}
                    />
                    <Label for="privacyQuestion2">
                      Descreva Questão 2
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="privacyAnswer2"
                      type="text"
                      required
                      value={data.privacyAnswer2}
                      onChange={(event) => setData({...data, privacyAnswer2: event.target.value})}
                    />
                    <Label for="privacyAnswer2">
                      Resposta Questão 2
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>

            <Col md={6}>
              <Row><h3>Questões de Funcionamento:</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="operationQuestion1"
                      type="text"
                      required
                      value={data.operationQuestion1}
                      onChange={(event) => setData({...data, operationQuestion1: event.target.value})}
                    />
                    <Label for="operationQuestion1">
                      Descreva Questão 1
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="operationAnswer1"
                      type="text"
                      required
                      value={data.operationAnswer1}
                      onChange={(event) => setData({...data, operationAnswer1: event.target.value})}
                    />
                    <Label for="operationAnswer1">
                      Resposta Questão 1
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="operationQuestion2"
                      type="text"
                      required
                      value={data.operationQuestion2}
                      onChange={(event) => setData({...data, operationQuestion2: event.target.value})}
                    />
                    <Label for="operationQuestion2">
                      Descreva Questão 2
                    </Label>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup floating>
                    <Input
                      name="operationAnswer2"
                      type="text"
                      required
                      value={data.operationAnswer2}
                      onChange={(event) => setData({...data, operationAnswer2: event.target.value})}
                    />
                    <Label for="operationAnswer2">
                      Resposta Questão 2
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>      
          </Row>
        </form>
      </section>
    </div>
  )
}