import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './HypothesisTesting.scss';

export default function HypothesisTesting(props: ExerciseProps) { 
  const [data, setData] = useState({
    number: parseInt(props.answer.answer?.number) || 0,
    hypothesis: props.answer.answer?.hypothesis || '',
    type: props.answer.answer?.type || '',
  })

  useEffect(() => {
    const isCompleted = false
    const answer = new Answer({
      ...props.answer, 
      answer: data,
      isCompleted
    })
    
    props.save(answer)
    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="hypothesis-testing-form" action="">
          <Row>
            <Col className="hypothesis-testing-form-first-column" md={4}>
              <div className="hypothesis-testing-radio-container">
                <input 
                  type="radio" 
                  name="number" 
                  id="number1" 
                  checked={data.number === 1}
                  onChange={(event) => setData({...data, number: 1})}
                />
                <label htmlFor="number1">1ª Hipótese</label>
              </div>
              <div className="hypothesis-testing-radio-container">
                <input 
                  type="radio" 
                  name="number" 
                  id="number2" 
                  checked={data.number === 2}
                  onChange={(event) => setData({...data, number: 2})}  
                />
                <label htmlFor="number2">2ª Hipótese</label>  
              </div>   
              <div className="hypothesis-testing-radio-container">
                <input 
                    type="radio" 
                    name="number" 
                    id="number3" 
                    checked={data.number === 3}
                    onChange={(event) => setData({...data, number: 3})}  
                  />
                  <label htmlFor="number3">3ª Hipótese</label>
              </div>      
            </Col>
            <Col className="hypothesis-testing-form-second-column" md={8}>
              <div className="hypothesis-testing-input-container">
                <input 
                  type="text" 
                  name="hypothesis" 
                  id="hypothesis" 
                  placeholder="Descrição da Hipótese Testada" 
                  value={data.hypothesis}
                  onChange={(event) => setData({...data, hypothesis: event.target.value})}
                />
              </div>
              <div className="hypothesis-testing-buttons-container">
                <button 
                    type="button"
                    onClick={(event) => setData({...data, type: 'validated'})}
                    className={`${data.type === 'validated' ? 'active' : 'not-active'}`}
                  >Validada
                </button>
                <button 
                    type="button"
                    onClick={(event) => setData({...data, type: 'adjusted'})}
                    className={`${data.type === 'adjusted' ? 'active' : 'not-active'}`}
                  >Ajustada
                </button>
                <button 
                    type="button"
                    onClick={(event) => setData({...data, type: 'extended'})}
                    className={`${data.type === 'extended' ? 'active' : 'not-active'}`}
                  >Prorrogada
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}