import React, { useEffect, useState } from "react"
import InputMask from "react-input-mask"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import Layout from "../../../../../components/layout/Layout"
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap"
import { User } from "../../../../../entities"
import UserStorage from "../../../../../util/userStorage"
import { AuthenticationService } from "../../../../../services/AuthenticationService"
import { UserService } from "../../../../../services/UserService"

import "../../../Dashboard.scss"

const states = [
  { value: "AC", label: "Acre" },
  { value: "AL", label: "Alagoas" },
  { value: "AP", label: "Amapá" },
  { value: "AM", label: "Amazonas" },
  { value: "BA", label: "Bahia" },
  { value: "CE", label: "Ceará" },
  { value: "DF", label: "Distrito Federal" },
  { value: "ES", label: "Espírito Santo" },
  { value: "GO", label: "Goías" },
  { value: "MA", label: "Maranhão" },
  { value: "MT", label: "Mato Grosso" },
  { value: "MS", label: "Mato Grosso do Sul" },
  { value: "MG", label: "Minas Gerais" },
  { value: "PA", label: "Pará" },
  { value: "PB", label: "Paraíba" },
  { value: "PR", label: "Paraná" },
  { value: "PE", label: "Pernambuco" },
  { value: "PI", label: "Piauí" },
  { value: "RJ", label: "Rio de Janeiro" },
  { value: "RN", label: "Rio Grande do Norte" },
  { value: "RS", label: "Rio Grande do Sul" },
  { value: "RO", label: "Rondônia" },
  { value: "RR", label: "Roraíma" },
  { value: "SC", label: "Santa Catarina" },
  { value: "SP", label: "São Paulo" },
  { value: "SE", label: "Sergipe" },
  { value: "TO", label: "Tocantins" },
]

export default function FounderFormPage() {
  const params = useParams()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [user, setUser] = useState(
    new User({
      role: { code: "founder" },
    })
  )

  useEffect(() => {
    if (params?.id === "me") {
      const user = UserStorage.get()
      if (user?.role?.code !== "founder") return
      if (!user?.id) return
      load(user.id)
    } else if (params?.id !== "0") {
      if (!params.id) return
      load(params.id)
    }
  }, [])

  const load = async (id: string) => {
    const authService = new AuthenticationService()
    const user = await UserService.get(id)
    setUser(user)
  }

  const save = async (event: any) => {
    event.preventDefault()
    const userToSave = new User({ ...user })
    if (searchParams.get("startup")) {
      userToSave.startup = new User({ id: searchParams.get("startup") })
    }
    await UserService.save(userToSave)

    if (params?.id === "me") {
      alert("Perfil salvo com sucesso.")
    } else {
      navigate(`/startup/${searchParams.get("startup")}`)
    }
  }

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user)
    userNew.setAttributeValue(code, value)
    setUser(userNew)
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>Perfil do(a) Fundador(a)</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Informações Pessoais</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="name"
                  name="name"
                  placeholder="Digite o seu nome completo"
                  type="text"
                  required
                  value={user.getAttributeValue("name")}
                  onChange={(event) =>
                    changeFieldValue("name", event.currentTarget.value)
                  }
                />
                <Label for="name">Nome completo</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="999.999.999-99"
                  maskChar="-"
                  id="cpf"
                  name="cpf"
                  placeholder="Digite o seu CPF"
                  type="text"
                  required
                  value={user.getAttributeValue("document")}
                  onChange={(event) => {
                    let val = event.target.value
                    val = val.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                      "$1.$2.$3/$4-$5"
                    )
                    changeFieldValue("document", val)
                  }}
                />
                <Label for="cpf">CPF</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="birthDate">Data de Nascimento</Label>
                <Input
                  tag={InputMask}
                  mask="99/99/9999"
                  maskChar={null}
                  id="birthDate"
                  name="birthDate"
                  placeholder="Data de nascimento"
                  type="text"
                  value={user.getAttributeValue("birthDate")}
                  onChange={(event) =>
                    changeFieldValue("birthDate", event.currentTarget.value)
                  }
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="educationalLevel">Formação Acadêmica</Label>
                <Input
                  id="educationalLevel"
                  name="educationalLevel"
                  type="select"
                  value={user.getAttributeValue("educationalLevel")}
                  onChange={(event) =>
                    changeFieldValue(
                      "educationalLevel",
                      event.currentTarget.value
                    )
                  }
                >
                  <option value="" selected disabled>
                    Selecione a Formação Acadêmica
                  </option>
                  <option>Técnologo</option>
                  <option>Bacharelado</option>
                  <option>Licenciatura</option>
                  <option>Especialização</option>
                  <option>Pós-graduação</option>
                  <option>Mestrado</option>
                  <option>Doutorado</option>
                  <option>Pós-doutorado</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="race">Raça</Label>
                <Input
                  id="race"
                  name="race"
                  type="select"
                  value={user.getAttributeValue("race")}
                  onChange={(event) =>
                    changeFieldValue("race", event.currentTarget.value)
                  }
                >
                  <option value="" selected disabled>
                    Selecione a Raça
                  </option>
                  <option value="branca">Branca</option>
                  <option value="preta">Preta</option>
                  <option value="parda">Parda</option>
                  <option value="indigena">Indígena</option>
                  <option value="amarela">Amarela</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="gender">Gênero</Label>
                <Input
                  id="gender"
                  name="gender"
                  type="select"
                  value={user.getAttributeValue("gender")}
                  onChange={(event) =>
                    changeFieldValue("gender", event.currentTarget.value)
                  }
                >
                  <option value="" selected disabled>
                    Selecione o Gênero
                  </option>
                  <option value="female">Feminino</option>
                  <option value="male">Masculino</option>
                  <option value="transgender">Transgênero</option>
                  <option value="neutral">Gênero Neutro</option>
                  <option value="non-binary">Não Binário</option>
                  <option value="agender">Agênero</option>
                  <option value="pangender">Pangênero</option>
                  <option value="genderqueer">Genderqueer</option>
                  <option value="two-spirit">Two-Spirit</option>
                  <option value="third-gender">Terceiro Gênero</option>
                  <option value="other">Outro</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="citizenship">Nacionalidade</Label>
                <Input
                  id="citizenship"
                  name="citizenship"
                  type="select"
                  value={user.getAttributeValue("citizenship")}
                  onChange={(event) =>
                    changeFieldValue("citizenship", event.currentTarget.value)
                  }
                >
                  <option value="" selected disabled>
                    Selecione a Nacionalidade
                  </option>
                  <option>Brasileiro</option>
                  <option>Estrangeiro</option>
                  <option>Outro</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup className="separated-label">
                <Label for="naturalness">Naturalidade</Label>
                <Input
                  id="naturalness"
                  name="naturalness"
                  type="select"
                  value={user.getAttributeValue("naturalness")}
                  onChange={(event) =>
                    changeFieldValue("naturalness", event.currentTarget.value)
                  }
                >
                  <option selected disabled>
                    Selecione a Naturalidade
                  </option>
                  <option value="brazilian">Brasileiro</option>
                  <option value="foreign">Estrangeiro</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup className="separated-label">
                <Label for="pcd">PCD?</Label>
                <Input
                  id="pcd"
                  name="pcd"
                  type="select"
                  value={user.getAttributeValue("pcd")}
                  onChange={(event) =>
                    changeFieldValue("pcd", event.currentTarget.value)
                  }
                >
                  <option value="" selected disabled>
                    Selecione uma opção
                  </option>
                  <option>Não</option>
                  <option>Sim</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Contatos</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Digite o seu email"
                  type="email"
                  required
                  value={user.email}
                  onChange={(event) =>
                    setUser(
                      new User({ ...user, email: event.currentTarget.value })
                    )
                  }
                />
                <Label for="email">Email</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="+(99) 99 99999-9999"
                  maskChar={null}
                  id="whatsapp"
                  name="whatsapp"
                  placeholder="Digite o seu WhatsApp"
                  type="text"
                  value={user.getAttributeValue("whatsapp")}
                  onChange={(event) =>
                    changeFieldValue("whatsapp", event.currentTarget.value)
                  }
                />
                <Label for="whatsapp">WhatsApp</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="linkedin"
                  name="linkedin"
                  placeholder="Digite o seu LinkedIn"
                  type="text"
                  value={user.getAttributeValue("linkedin")}
                  onChange={(event) =>
                    changeFieldValue("linkedin", event.currentTarget.value)
                  }
                />
                <Label for="linkedin">LinkedIn</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="instagram"
                  name="instagram"
                  placeholder="Digite o seu Instagram"
                  type="text"
                  value={user.getAttributeValue("instagram")}
                  onChange={(event) =>
                    changeFieldValue("instagram", event.currentTarget.value)
                  }
                />
                <Label for="instagram">Instagram</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Endereço Residencial</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="99999-999"
                  maskChar={null}
                  id="zipcode"
                  name="zipcode"
                  placeholder="Digite o seu CEP"
                  type="text"
                  required
                  value={user.getAttributeValue("zipcode")}
                  onChange={(event) =>
                    changeFieldValue("zipcode", event.currentTarget.value)
                  }
                />
                <Label for="zipcode">CEP</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="street"
                  name="street"
                  placeholder="Digite o seu logradouro"
                  type="text"
                  required
                  value={user.getAttributeValue("street")}
                  onChange={(event) =>
                    changeFieldValue("street", event.currentTarget.value)
                  }
                />
                <Label for="street">Logradouro</Label>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup floating>
                <Input
                  id="neighborhood"
                  name="neighborhood"
                  placeholder="Digite o seu bairro"
                  type="text"
                  required
                  value={user.getAttributeValue("neighborhood")}
                  onChange={(event) =>
                    changeFieldValue("neighborhood", event.currentTarget.value)
                  }
                />
                <Label for="neighborhood">Bairro</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup floating>
                <Input
                  id="number"
                  name="number"
                  placeholder="Digite o nº"
                  type="number"
                  required
                  value={user.getAttributeValue("number")}
                  onChange={(event) =>
                    changeFieldValue("number", event.currentTarget.value)
                  }
                />
                <Label for="number">Nº</Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup floating>
                <Input
                  id="complement"
                  name="complement"
                  placeholder="Digite o complemento"
                  type="text"
                  required
                  value={user.getAttributeValue("complement")}
                  onChange={(event) =>
                    changeFieldValue("complement", event.currentTarget.value)
                  }
                />
                <Label for="complement">Complemento</Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup floating>
                <Input
                  id="city"
                  name="city"
                  placeholder="Digite a sua cidade"
                  type="text"
                  required
                  value={user.getAttributeValue("city")}
                  onChange={(event) =>
                    changeFieldValue("city", event.currentTarget.value)
                  }
                />
                <Label for="city">Cidade</Label>
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup floating>
                <Input
                  id="state"
                  name="state"
                  type="select"
                  value={user.getAttributeValue("state")}
                  onChange={(event) =>
                    changeFieldValue("state", event.target.value)
                  }
                >
                  <option value={""}>Selecionar Estado</option>
                  {states.map((state) => (
                    <option value={state.value}>{state.label}</option>
                  ))}
                </Input>
                <Label for="state">Estado</Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Startups</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="role"
                  name="role"
                  placeholder="Função"
                  type="text"
                  required
                  value={user.getAttributeValue("role")}
                  onChange={(event) =>
                    changeFieldValue("role", event.currentTarget.value)
                  }
                />
                <Label for="role">Função</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  tag={InputMask}
                  mask="999%"
                  maskChar={null}
                  id="equity"
                  name="equity"
                  placeholder="Participação (%)"
                  type="text"
                  required
                  value={user.getAttributeValue("equity")}
                  onChange={(event) =>
                    changeFieldValue("equity", event.currentTarget.value)
                  }
                />
                <Label for="equity">Participação (%)</Label>
              </FormGroup>
            </Col>
          </Row>
          {/* <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Possui relação com uma Startup?</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup className="separated-label">
                <Input
                  id="relatedToStartup"
                  name="relatedToStartup"
                  type="select"
                  value={user.getAttributeValue('birthDate')}
                  onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                >
                  <option value="" selected disabled>Selecione uma opção</option>
                  <option>
                    Sim
                  </option>
                  <option>
                    Não
                  </option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          */}
          {/* <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Startups Relacionadas</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <div className="form-group-container">
                <FormGroup floating>
                  <Input
                    id="relatedStartup1"
                    name="relatedStartup1"
                    placeholder="Digite o nome da Startup"
                    type="text"
                    required
                    value={user.getAttributeValue('birthDate')}
                  onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  />
                  <Label for="relatedStartup1">
                    Startup Relacionada 1
                  </Label>
                </FormGroup>
                <FormGroup className="separated-label">
                  <Input
                    id="function"
                    name="function"
                    type="select"
                    value={user.getAttributeValue('birthDate')}
                    onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  >
                    <option value="" selected disabled>Selecione uma função</option>
                    <option>
                      Fundador(a)
                    </option>
                    <option>
                      Sócio(a)
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="ownership"
                    name="ownership"
                    placeholder="Porcentagem de Startup"
                    type="number"
                    required
                    value={user.getAttributeValue('birthDate')}
                    onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  />
                  <Label for="ownership">
                    (%) da Startup
                  </Label>
                </FormGroup>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group-container">
                <FormGroup floating>
                  <Input
                    id="relatedStartup2"
                    name="relatedStartup2"
                    placeholder="Digite o nome da Startup"
                    type="text"
                    required
                    value={user.getAttributeValue('birthDate')}
                    onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  />
                  <Label for="relatedStartup2">
                    Startup Relacionada 2
                  </Label>
                </FormGroup>
                <FormGroup className="separated-label">
                  <Input
                    id="function"
                    name="function"
                    type="select"
                    value={user.getAttributeValue('birthDate')}
                    onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  >
                    <option value="" selected disabled>Selecione uma função</option>
                    <option>
                      Fundador(a)
                    </option>
                    <option>
                      Sócio(a)
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="ownership"
                    name="ownership"
                    placeholder="Porcentagem de Startup"
                    type="number"
                    required
                    value={user.getAttributeValue('birthDate')}
                    onChange={(event) => changeFieldValue('birthDate', event.currentTarget.value)}
                  />
                  <Label for="ownership">
                    (%) da Startup
                  </Label>
                </FormGroup>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group-container">
                <FormGroup floating>
                  <Input
                    id="relatedStartup3"
                    name="relatedStartup3"
                    placeholder="Digite o nome da Startup"
                    type="text"
                    required
                    onChange={(event) => {}}
                  />
                  <Label for="relatedStartup3">
                    Startup Relacionada 3
                  </Label>
                </FormGroup>
                <FormGroup className="separated-label">
                  <Input
                    id="function"
                    name="function"
                    type="select"
                  >
                    <option value="" selected disabled>Selecione uma função</option>
                    <option>
                      Fundador(a)
                    </option>
                    <option>
                      Sócio(a)
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="ownership"
                    name="ownership"
                    placeholder="Porcentagem de Startup"
                    type="number"
                    required
                    onChange={(event) => {}}
                  />
                  <Label for="ownership">
                    (%) da Startup
                  </Label>
                </FormGroup>
              </div>
            </Col>
            <Col md={3}>
              <div className="form-group-container">
                <FormGroup floating>
                  <Input
                    id="relatedStartup4"
                    name="relatedStartup4"
                    placeholder="Digite o nome da Startup"
                    type="text"
                    required
                    onChange={(event) => {}}
                  />
                  <Label for="relatedStartup4">
                    Startup Relacionada 4
                  </Label>
                </FormGroup>
                <FormGroup className="separated-label">
                  <Input
                    id="function"
                    name="function"
                    type="select"
                  >
                    <option value="" selected disabled>Selecione uma função</option>
                    <option>
                      Fundador(a)
                    </option>
                    <option>
                      Sócio(a)
                    </option>
                  </Input>
                </FormGroup>
                <FormGroup floating>
                  <Input
                    id="ownership"
                    name="ownership"
                    placeholder="Porcentagem de Startup"
                    type="number"
                    required
                    onChange={(event) => {}}
                  />
                  <Label for="ownership">
                    (%) da Startup
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row> */}
          <Button onClick={save} type="submit">
            Salvar
          </Button>
        </Form>
      </section>
    </Layout>
  )
}
