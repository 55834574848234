import { SearchParams, SearchResult, User } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class UserRepository {
    public static async serch(params: SearchParams, token: string): Promise<SearchResult<User>> {
        const headers = { Authorization: token };
        
        const url = `/user?page=${params.page}&limit=${params.limit}${params.getFilterString()}`
        const response = await client.DoRequest('GET', url, {}, headers)

        const result = new SearchResult<User>(response.data, response.info)

        return result
    }

    public static async get(id: string, token: string): Promise<User> {
        const headers = { Authorization: token };
        const url = `/user/${id}`
        const response = await client.DoRequest('GET', url, {}, headers)

        return new User(response)
    }

    public static async add(user: User, token: string): Promise<void> {
        const headers = { Authorization: token };
        
        await client.DoRequest('POST', `/user`, user, headers)
    }

    public static async update(user: User, token: string): Promise<void> {
        const headers = { Authorization: token };
        
        await client.DoRequest('PATCH', `/user/${user.id}`, user, headers)
    }

    public static async getFounders(id: string, token: string): Promise<User[]> {
        const headers = { Authorization: token };
        const url = `/user/getFounders/${id}`    
        const response = await client.DoRequest('GET', url, {}, headers)

        const founders: User[] = []
        for (const founderData of response) {
            founders.push(new User(founderData))
        }

        return founders
    }
}