import React, { useEffect } from "react"
import Layout from "../../components/layout/Layout"
import Banner from "../../components/banner/Banner"
import AboutUsExtension from "../../components/aboutUs/extension/AboutUsExtension"
import AboutUs from "../../components/aboutUs/AboutUs"

//import Methodology from '../../components/methodology/Methodology';
//import News from '../../components/news/News';

import Hacktown from "../../components/hacktown/Hacktown"
import Faq from "../../components/faq/Faq"
import Partners from "../../components/partners/Partners"
import Contact from "../../components/contact/Contact"

//import DiagonalBlackStripeTop from "../../components/hacktown/extension/DiagonalBlackStripeTop"
import DiagonalBlackStripeBottom from "../../components/hacktown/extension/DiagonalBlackStripeBottom"

import Ecosystem from "../../components/ecosystem/Ecosystem"

import "./HomePage.scss"
import WhiteLabelStorage from "../../util/whiteLabelStorage"
import { useNavigate } from "react-router-dom"
import { useWhitelabel } from "../../stores/WhiteLabelStore"

export default function HomePage() {
  const navigate = useNavigate()
  const whitelabel = useWhitelabel().get()

  useEffect(() => {
    const id = WhiteLabelStorage.getId()
    if (whitelabel && whitelabel?.home !== true) {
      navigate('/painel')
    }
  }, [whitelabel])

  return (
    <Layout>
      <Banner />
      <AboutUsExtension />
      <AboutUs />
      {/* <Methodology /> */}
      {/* <DiagonalBlackStripeTop /> */}
      <Hacktown />
      <DiagonalBlackStripeBottom />
      {/* <News /> */}
      <Ecosystem />
      <Faq />
      <Contact />
      <Partners />
    </Layout>
  )
}
