import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './WireframesShow.scss';
import { AnswerService } from '../../../services';
import { FileUploadService } from '../../../services/upload/FileUploadService';

export default function WireframesShow(props: ExerciseProps) { 
  const [data, setData] = useState({
    link: props.answer.answer?.link || '',
    loginScreen: props.answer.answer?.loginScreen || 'https://fl-1.cdn.flockler.com/embed/no-image.svg',
    step1Screen: props.answer.answer?.step1Screen || 'https://fl-1.cdn.flockler.com/embed/no-image.svg',
    step2Screen: props.answer.answer?.step2Screen || 'https://fl-1.cdn.flockler.com/embed/no-image.svg',
    step3Screen: props.answer.answer?.step3Screen || 'https://fl-1.cdn.flockler.com/embed/no-image.svg',
    step4Screen: props.answer.answer?.step4Screen || 'https://fl-1.cdn.flockler.com/embed/no-image.svg'
  })

  useEffect(() => {
    const isCompleted = false
    const answer = new Answer({
      ...props.answer, 
      answer: data,
      isCompleted
    })

    console.log('pos.answer', props.answer)
    console.log(answer)
    
    props.save(answer)
    // eslint-disable-next-line
  }, [data])

  const convertBase64 = (file:any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const changeFile = async (event: any, fieldName: string) => {
    const img = {
      preview: URL.createObjectURL(event.target.files[0]),
      data: event.target.files[0],
    }
    
    let formData = new FormData()
    formData.append('file', img.data)

    const response = await FileUploadService.upload(formData)
    if (response.success === true) {
      const newData: any = {...data}
      newData[fieldName] = response?.file?.id
      setData(newData)
    }
  }

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Faça upload dos desenhos de telas conforme a atividade e adicione o link da versão digital:</h2>
        </div>
        <form className="wireframes-show-form" action="">
          <Row>
            <Col className="wireframes-show-form-column" md={12}>
              <div className="wireframes-show-file-container">
                <h3>Login</h3>
                <label htmlFor="fileStep1">
                  <img src={`${process.env.REACT_APP_API_URI}/file/get/${data.loginScreen}`} width={150} height={150} alt="Alt" />
                </label>
                <input 
                  type="file" 
                  name="fileStep1" 
                  id="fileStep1" 
                  onChange={async (event) => {
                    if (event.target.files) {
                      const sizeMb = event.target.files[0].size / (1024 * 1024)
                      console.log('file', event.target.files[0])
                      console.log('file', sizeMb)
                      if (sizeMb > 5) {
                        alert('O arquivo deve ter no máximo 5MB.')
                        setData({...data, loginScreen: 'https://fl-1.cdn.flockler.com/embed/no-image.svg'})
                        return
                      }

                      // const imageBase64 = await convertBase64(event.target.files[0])                      
                      // if (typeof imageBase64 === 'string') {
                      //   console.log('imageBase64', imageBase64)
                      //   const imageId = await AnswerService.upload(props.id, 'login', imageBase64)
                      //   console.log('image-id', imageId)
                      //   setData({...data, loginScreen: imageId})
                      // }

                      changeFile(event, 'loginScreen')
                      
                    }
                  }}
                />
              </div>   
              <div className="wireframes-show-file-container">
                <h3>Passo 1</h3>
                <label htmlFor="fileStep2">
                  <img src={data.step1Screen} alt="Step 1 screenshot" />
                </label>
                <input 
                  type="file" 
                  name="fileStep2" 
                  id="fileStep2" 
                  onChange={async (event) => {
                    if (event.target.files) {
                      const imageBase64 = await convertBase64(event.target.files[0])
                      setData({...data, step1Screen: imageBase64})
                    }
                  }}
                />
              </div>
              <div className="wireframes-show-file-container">
                <h3>Passo 2</h3>
                <label htmlFor="fileStep3">
                  <img src={data.step2Screen} alt="Alt" />
                </label>
                <input 
                  type="file" 
                  name="fileStep3" 
                  id="fileStep3" 
                  onChange={async (event) => {
                    if (event.target.files) {
                      const imageBase64 = await convertBase64(event.target.files[0])
                      setData({...data, step2Screen: imageBase64})
                    }
                  }}
                />
              </div>  
              <div className="wireframes-show-file-container">
                <h3>Passo 3</h3>
                <label htmlFor="fileStep4">
                  <img src={data.step3Screen} alt="Alt" />
                </label>
                <input 
                  type="file" 
                  name="fileStep4" 
                  id="fileStep4" 
                  onChange={async (event) => {
                    if (event.target.files) {
                      const imageBase64 = await convertBase64(event.target.files[0])
                      setData({...data, step3Screen: imageBase64})
                    }
                  }}
                />
              </div> 
              <div className="wireframes-show-file-container">
                <h3>Passo 4</h3>
                <label htmlFor="fileStep5">
                  <img src={data.step4Screen} alt="Alt" />
                </label>
                <input 
                  type="file" 
                  name="fileStep5" 
                  id="fileStep5" 
                  onChange={async (event) => {
                    if (event.target.files) {
                      const imageBase64 = await convertBase64(event.target.files[0])
                      setData({...data, step4Screen: imageBase64})
                    }
                  }}
                />
              </div>      
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <div className="wireframes-show-form-field">
                <input 
                  type="text" 
                  name="link" 
                  id="addLink" 
                  placeholder="Adicione aqui o link da versão digital" 
                  value={data.link}
                  onChange={(event) => setData({...data, link: event.target.value})}
                  />
              </div>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}