import { WhiteLabelRepository } from "../../repositories/whitelabel/WhiteLabelRepository"
import { accessWhitelabel } from "../../stores/WhiteLabelStore"
import WhiteLabelStorage from "../../util/whiteLabelStorage"

export class WhiteLabelService {
  public static async resolve(): Promise<string> {
    if (WhiteLabelStorage.hasId() === true) {
      return WhiteLabelStorage.getId()
    }

    const hostname = window.location.hostname
    const whiteLabelId = await WhiteLabelRepository.resolve(hostname)

    if (whiteLabelId) {
      WhiteLabelStorage.setId(whiteLabelId)
    }
    return whiteLabelId
  }

  public static async getConfigs(): Promise<any> {
    if (WhiteLabelStorage.hasConfigs() === true && Math.random() < 0.5) {
      const configs = WhiteLabelStorage.getConfigs()
      accessWhitelabel().set(configs)
      return configs
    }

    const id = await WhiteLabelService.resolve()
    const configs = await WhiteLabelRepository.getConfigs(id)

    if (configs) {
      WhiteLabelStorage.setConfigs(configs)
      accessWhitelabel().set(configs)
    }

    return configs
  }
}