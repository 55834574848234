import React from "react"
import { CgShapeCircle } from "react-icons/cg"
import { Flex, Text, Image, Box, Button, AspectRatio } from "@chakra-ui/react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"

import "./Hacktown.scss"

//import theme from "../../Themes"

export default function Hacktown() {
  return (
    <section id="hacktown" className="hacktown-container">
      <div className="content">
        <h2>
          <CgShapeCircle /> A Ação do Hacktown
        </h2>
        <div className="information">
          <div className="statistics">
            <div className="box">
              <div>
                <span>+ 1.700</span>
                <span>
                  comunidade <br />
                  Valide.se
                </span>
              </div>
              <div>
                <span>+ 31.000</span>
                <span>
                  comunidade <br />
                  HackTown
                </span>
              </div>
            </div>
            <div className="box box-end">
              <div>
                <span>+ 4.500</span>
                <span>
                  alcance dos <br />
                  licenciados
                </span>
              </div>
              <div>
                <span>+ 100.000</span>
                <span>
                  alcance dos <br />
                  convidados
                </span>
              </div>
            </div>
            <div className="hacktown-logo">
              <img
                src="/images/homepage/supporters/apoiador-hacktown.png"
                alt="Apoiador Hacktown"
              />
            </div>
          </div>
          <div className="description">
            <Flex w="100%" justifyContent="center">
              <AspectRatio w="100%" maxW="600px" ratio={2}>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/EQxhvwzcTLY?si=17B2vcKIQAqTqm1i"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </AspectRatio>
            </Flex>
            <span>
              Instalaremos nossa ilha de podcast em um dos pontos mais badalados
              do evento, por onde passam 100% das pessoas que fazem parte do
              público, bem no coreto da Praça Central da cidade.
            </span>
            <span>
              Foram ao menos 10 conteúdos transmitidos da ilha e mais 6 entradas
              ao vivo pelas nossas redes sociais em outros pontos da cidade.
            </span>
          </div>
        </div>
        {/* <div className="supporters">
          <h3>Apoiadores</h3>
          <div className="supporters-carousel-container">
            <Swiper
              spaceBetween={0}
              slidesPerView={4}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              onSlideChange={() => {}}
              onSwiper={(swiper) => {}}
              modules={[Autoplay, Navigation]}
              navigation={true}
              className="supporters-carousel"
            >
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-box404.png"
                  alt="Apoiador Box404"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-hacktown.png"
                  alt="Apoiador Hacktown"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-siao.png"
                  alt="Apoiador Siao"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-sohms.png"
                  alt="Apoiador Sohms"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-box404.png"
                  alt="Apoiador Box404"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-hacktown.png"
                  alt="Apoiador Hacktown"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-siao.png"
                  alt="Apoiador Siao"
                />
              </SwiperSlide>
              <SwiperSlide className="supporter-logo">
                <img
                  src="/images/homepage/supporters/apoiador-sohms.png"
                  alt="Apoiador Sohms"
                />
              </SwiperSlide>
            </Swiper>
          </div>
        </div> */}
        <div className="youtube-redirect">
          <a
            href="https://www.youtube.com/watch?v=i7HrT2bpNEo&ab_channel=ValideSe"
            target="_blank"
            rel="noreferrer"
          >
            Assista no YouTube
          </a>
        </div>
      </div>
    </section>
  )
}
