import React from "react"
import { Flex, Text, Image } from "@chakra-ui/react"

import { useWhitelabel } from "../../../stores/WhiteLabelStore"

export default function DashboardMentor() {
  const whitelabel = useWhitelabel().get()

  return (
    <Flex
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex w="100%" maxWidth="1440px" p="1rem" direction="column">
        <Text
          color={whitelabel?.colors?.quinary}
          fontSize={{ base: "1.5rem", sm: "2rem", md: "2rem", lg: "2rem" }}
          fontWeight="600"
          alignSelf="center"
          bg={whitelabel?.colors?.secondary}
          p="0.25rem 1.5rem"
          borderRadius="0.5rem"
        >
          Dashboard do Mentor
        </Text>

        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Informações do Mentor
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startups Atendidas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                59
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Horas de Mentorias Executadas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                973
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Número de Aceleradoras Beneficiadas
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                98
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Avaliação
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                9.5 / 10
              </Text>
            </Flex>
          </Flex>
        </Flex>

        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Startups Investidas
          </Text>
          <Flex
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            flexWrap="wrap"
          >
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup A
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup B
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
            <Flex direction="column" w="100%" maxW="200px" alignItems="center">
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Startup C
              </Text>
              <Flex alignItems="center" maxW="100px">
                <Image
                  w="100%"
                  src="/images/icon-validese.png"
                  alt="Ícone da Valide.se"
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
