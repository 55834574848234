import { Answer, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class AnswerRepository {
    public static async search(params: SearchParams, token: string): Promise<SearchResult<Answer>> {
        const headers = { Authorization: token };
        
        const url = `/answer?page=${params.page}&limit=${params.limit}${params.getFilterString()}`
        const response = await client.DoRequest('GET', url, {}, headers)

        const result = new SearchResult<Answer>(response.data, response.info)

        return result
    }

    public static async getByAccelerationId(accelerationId: string, token: string): Promise<Answer[]> {
        const headers = { Authorization: token };
        
        const response = await client.DoRequest('GET', `/answer/acceleration/${accelerationId}`, {}, headers)

        const answers: Answer[] = []
        if (Array.isArray(response)) {
            for (const item of response) {
                answers.push(new Answer(item))
            }    
        }

        return answers
    }

    public static async add(answer: Answer, token: string): Promise<void> {
        const headers = { Authorization: token };
        
        await client.DoRequest('POST', '/answer', answer, headers)
    }

    public static async update(answer: Answer, token: string): Promise<void> {
        const headers = { Authorization: token };
        
        await client.DoRequest('PATCH', `/answer/${answer.id}`, answer, headers)
    }

    public static async upload(id: string, type: string, imageData: string, token: string): Promise<string> {
        const headers = { Authorization: token };

        const data = {
            id, 
            type,
            image: imageData
        }
    
        console.log('url', `/answer/upload/${id}/${type}`)
        const response = await client.DoRequest('POST', `/answer/upload`, data, headers)
        
        return response.id
    }
}