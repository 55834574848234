import React, { useState } from "react"
import { Flex, Text, Image, Box, Button, Link } from "@chakra-ui/react"
import { CgShapeCircle } from "react-icons/cg"
import { useWhitelabel } from "../../stores/WhiteLabelStore"
import CountUp from "react-countup"
//import ScrollTrigger from "react-scroll-trigger"
import "./Ecosystem.scss"

export default function Ecosystem() {
  //const [counterOn, setCounterOn] = useState(false)

  const whitelabel = useWhitelabel().get()

  const scrollSection = (id: string) => {
    console.log(window.location.pathname)
    if (window.location.pathname === "/") {
      const section = document.querySelector(`#${id}`)
      section?.scrollIntoView({ behavior: "smooth" })
      return false
    }
  }

  return (
    <Flex
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex w="100%" maxWidth="1200px" p="0 1rem 0 1rem" direction="column">
        <Text
          alignSelf="flex-start"
          color={whitelabel?.colors?.tertiary}
          fontSize="1.25rem"
          fontWeight="700"
          textTransform="uppercase"
          display="flex"
          alignItems="center"
          gap="0.5rem"
        >
          <Box color={whitelabel?.colors?.primary}>
            <CgShapeCircle />
          </Box>
          ECOSSISTEMA VALIDE.SE
        </Text>
        <Flex
          justifyContent="space-between"
          gap="1rem"
          direction={{ base: "column", sm: "column", md: "column", lg: "row" }}
        >
          <Flex
            w="100%"
            alignItems={{
              base: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            }}
            direction="column"
            gap="1rem"
          >
            <Text
              textAlign="start"
              color={whitelabel?.colors?.tertiary}
              fontWeight="600"
            >
              A Valide.se desempenha um papel fundamental na construção e
              fortalecimento do ecossistema de inovação e empreendedorismo,
              reunindo aceleradoras, startups, mentores e investidores em uma
              plataforma colaborativa.
            </Text>
            <Flex w="100%" flexWrap="wrap">
              <Flex
                w={{ base: "100%", sm: "100%", md: "50%" }}
                justifyContent={{
                  base: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                gap="0.5rem"
              >
                <Flex alignItems="flex-start">
                  <Image
                    mt="2.25rem"
                    maxWidth="5rem"
                    src="/images/homepage/plus-green.png"
                    alt="Ecossistema da Valide.se"
                  ></Image>
                </Flex>
                <Flex direction="column">
                  <Text
                    fontSize="6.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                    className="numbers"
                  >
                    <CountUp start={0} end={20} duration={10} delay={1} />
                  </Text>
                  <Text
                    fontSize="1.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    LICENCIADOS
                  </Text>
                </Flex>
              </Flex>
              <Flex
                w={{ base: "100%", sm: "100%", md: "50%" }}
                justifyContent={{
                  base: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                gap="0.5rem"
              >
                <Flex alignItems="flex-start">
                  <Image
                    mt="2.25rem"
                    maxWidth="5rem"
                    src="/images/homepage/plus-orange.png"
                    alt="Ecossistema da Valide.se"
                  ></Image>
                </Flex>
                <Flex direction="column">
                  <Text
                    fontSize="6.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    <CountUp start={0} end={50} duration={10} delay={1} />
                  </Text>
                  <Text
                    fontSize="1.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    INVESTIDORES
                  </Text>
                </Flex>
              </Flex>
              <Flex
                w={{ base: "100%", sm: "100%", md: "50%" }}
                justifyContent={{
                  base: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                gap="0.5rem"
              >
                <Flex alignItems="flex-start">
                  <Image
                    mt="2.25rem"
                    maxWidth="5rem"
                    src="/images/homepage/plus-black.png"
                    alt="Ecossistema da Valide.se"
                  ></Image>
                </Flex>
                <Flex direction="column">
                  <Text
                    fontSize="6.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    <CountUp start={0} end={100} duration={10} delay={1} />
                  </Text>
                  <Text
                    fontSize="1.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    MENTORES
                  </Text>
                </Flex>
              </Flex>
              <Flex
                w={{ base: "100%", sm: "100%", md: "50%" }}
                justifyContent={{
                  base: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                }}
                gap="0.5rem"
              >
                <Flex alignItems="flex-start">
                  <Image
                    mt="2.25rem"
                    maxWidth="5rem"
                    src="/images/homepage/plus-purple.png"
                    alt="Ecossistema da Valide.se"
                  ></Image>
                </Flex>
                <Flex direction="column">
                  <Text
                    fontSize="6.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    <CountUp start={0} end={100} duration={10} delay={1} />
                  </Text>
                  <Text
                    fontSize="1.25rem"
                    fontWeight="600"
                    color={whitelabel?.colors?.tertiary}
                    mb="0"
                  >
                    STARTUPS
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            w={{ base: "100%", sm: "100%", md: "100%", lg: "50%" }}
            justifyContent={{
              base: "center",
              sm: "center",
              md: "center",
              lg: "flex-start",
            }}
            alignItems="center"
          >
            <Image
              w="100%"
              maxWidth="500px"
              src="/images/homepage/eco-map.png"
              alt="Ecossistema da Valide.se"
            ></Image>
          </Flex>
        </Flex>
        <Flex w="100%" alignContent="center" justifyContent="center" mt="2rem">
          <Button
            onClick={() => scrollSection("contact")}
            borderRadius="2.5rem"
            bg={whitelabel?.colors?.primary}
            color={whitelabel?.colors?.quinary}
            fontWeight="500"
            _hover={{
              bg: `${whitelabel?.colors?.primary}`,
              color: `${whitelabel?.colors?.quinary}`,
            }}
          >
            Tenho Interesse
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
