import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
//import { Customer } from '../../entities';
//import { CustomerService } from '../../services/CustomerServices';
// import { AuthenticationService } from '../../services/AuthenticationService';
//import InputMask, {Props} from 'react-input-mask';
// import UserStorage from '../../util/userStorage';
import { Form, FormGroup, Input, Label, Button } from "reactstrap";
import { Image } from "@chakra-ui/react";

import "./Register.scss";

import { User } from "../../entities";
import { UserService } from "../../services/UserService";
import { useWhitelabel } from "../../stores/WhiteLabelStore";

export default function Register() {
  const whitelabel = useWhitelabel().get();

  const navigate = useNavigate();

  const [user, setUser] = useState(new User());
  const [licensedId, setLicensedId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const [searchParams] = useSearchParams();

  const [roles, setRoles] = useState([
    { code: "licensed", name: "Aceleradora" },
    { code: "startup", name: "Startup" },
    { code: "mentor", name: "Mentor" },
    { code: "investor", name: "Investor" },
    { code: "corporate", name: "Corporate" },
  ]);

  useEffect(() => {
    const licensedId = searchParams.get("aceleradora");
    if (licensedId) {
      setLicensedId(licensedId);
      const removeLicensedRole = (item: any) => {
        if (item.code === "licensed") return false;
        return true;
      };
      setRoles(roles.filter(removeLicensedRole));
    }

    // eslint-disable-next-line
  }, [searchParams]);

  const save = async (event: any) => {
    event.preventDefault();

    if (!user.id) {
      if (user.password?.length < 6) {
        alert("A senha deve ter pelo menos 6 caracteres.");
        return false;
      }
    }

    if (licensedId) {
      user.licensed = new User({ id: licensedId });
    }

    await UserService.save(user);

    alert("Cadastrado com sucesso!");
    navigate(`/login`);

    return false;
  };

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user);
    userNew.setAttributeValue(code, value);
    setUser(userNew);
  };

  return (
    <section className="form-page-container">
      <div className="register-container">
        <div className="logo">
          <Link to="/">
            <Image
              mb="2rem"
              src={whitelabel?.logo?.url}
              alt="Logo da Valide.se"
            />
          </Link>
        </div>
        <div className="title">
          <h1>Faça seu Cadastro</h1>
        </div>
        {errorMessage && (
          <div className="error-message">
            <span>{errorMessage}</span>
          </div>
        )}
        <Form>
          <FormGroup floating>
            <Input
              id="name"
              name="name"
              placeholder="Nome completo"
              type="text"
              required
              value={user.getAttributeValue("name")}
              onChange={(event) =>
                changeFieldValue("name", event.currentTarget.value)
              }
            />
            <Label for="email">Nome completo</Label>
          </FormGroup>{" "}
          <FormGroup floating>
            <Input
              tag={InputMask}
              mask="999.999.999-99"
              maskChar="-"
              id="cpf"
              name="cpf"
              placeholder="CPF"
              type="text"
              required
              disabled={false}
              value={user.getAttributeValue("document")}
              onChange={(event) =>
                changeFieldValue("document", event.currentTarget.value)
              }
            />
            <Label for="email">CPF</Label>
          </FormGroup>{" "}
          <FormGroup floating>
            <Input
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              required
              value={user.email}
              onChange={(event) =>
                setUser(new User({ ...user, email: event.currentTarget.value }))
              }
            />
            <Label for="email">Email</Label>
          </FormGroup>{" "}
          <FormGroup floating>
            <Input
              id="password"
              name="password"
              placeholder="Senha"
              type="password"
              required
              value={user.password}
              onChange={(event) =>
                setUser(
                  new User({ ...user, password: event.currentTarget.value })
                )
              }
            />
            <Label for="email">Senha</Label>
          </FormGroup>
          <FormGroup floating>
            <Input
              id="role"
              name="role"
              placeholder="Tipo"
              type="select"
              required
              value={user.role?.code}
              onChange={(event) =>
                setUser(
                  new User({
                    ...user,
                    role: { code: event.currentTarget.value },
                  })
                )
              }
            >
              <option value="">Selecione</option>
              {roles.map((role) => (
                <option value={role.code} key={role.code}>
                  {role.name}
                </option>
              ))}
            </Input>

            <Label for="role">Tipo</Label>
          </FormGroup>{" "}
          <Button onClick={save} type="submit">
            Cadastrar
          </Button>
        </Form>
        <div className="return-to-login-page">
          <span>Já possui uma conta?</span>
          <Link to="/login">Faça seu login</Link>
        </div>
      </div>
      <div className="register-banner-container">
        <picture>
          <img
            src="/images/login-startups.jpg"
            alt="Banner do formulário da Valide.se"
          />
        </picture>
      </div>
    </section>
  );
}
