import React from 'react';
import Footer from '../footer/Footer';
import Header from '../header/Header';
import MenuInternal from '../menuInternal/MenuInternal';

export default function Layout(props: any) {
  return (
    <>
      <Header internal={props.internal === true} />
      {props.internal === true && (
        <MenuInternal />
      )}
      {props.children}
      <Footer />
    </>
  );
}