import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week5.scss';


export default function Phase3Week5(props: ExerciseProps) {
  const [data, setData] = useState({
    trancheDescription1: props.answer.answer?.trancheDescription1 || '',
    trancheDescription2: props.answer.answer?.trancheDescription2 || '',
    trancheDescription3: props.answer.answer?.trancheDescription3 || '',
    trancheDescription4: props.answer.answer?.trancheDescription4 || '',

    trancheValue1: props.answer.answer?.trancheValue1 || '',
    trancheValue2: props.answer.answer?.trancheValue2 || '',
    trancheValue3: props.answer.answer?.trancheValue3 || '',
    trancheValue4: props.answer.answer?.trancheValue4 || '',
  })

  useEffect(() => {
    const isCompleted = data.trancheDescription1.length >= 1 && data.trancheDescription2.length >= 1 && 
    data.trancheDescription3.length >= 1 && data.trancheDescription4.length >= 1 && data.trancheValue1.length >= 1 && 
    data.trancheValue2.length >= 1 && data.trancheValue3.length >= 1 && data.trancheValue4.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase3-week5-form" action="">
          <Row>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      id="trancheDescription1"
                      name="trancheDescription1"
                      type="text"
                      required
                      value={data.trancheDescription1}
                      onChange={(event) => setData({...data, trancheDescription1: event.target.value})}
                    />
                    <Label for="trancheDescription1">
                      Descrição da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="trancheValue1"
                      name="trancheValue1"
                      type="number"
                      required
                      value={data.trancheValue1}
                      onChange={(event) => setData({...data, trancheValue1: event.target.value})}
                    />
                    <Label for="trancheValue1">
                      Valor da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      id="trancheDescription2"
                      name="trancheDescription2"
                      type="text"
                      required
                      value={data.trancheDescription2}
                      onChange={(event) => setData({...data, trancheDescription2: event.target.value})}
                    />
                    <Label for="trancheDescription2">
                      Descrição da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="trancheValue2"
                      name="trancheValue2"
                      type="number"
                      required
                      value={data.trancheValue2}
                      onChange={(event) => setData({...data, trancheValue2: event.target.value})}
                    />
                    <Label for="trancheValue2">
                      Valor da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      id="trancheDescription3"
                      name="trancheDescription3"
                      type="text"
                      required
                      value={data.trancheDescription3}
                      onChange={(event) => setData({...data, trancheDescription3: event.target.value})}
                    />
                    <Label for="trancheDescription3">
                      Descrição da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="trancheValue3"
                      name="trancheValue3"
                      type="number"
                      required
                      value={data.trancheValue3}
                      onChange={(event) => setData({...data, trancheValue3: event.target.value})}
                    />
                    <Label for="trancheValue3">
                      Valor da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={6}>
              <Row>
                <Col md={12}>
                  <FormGroup floating>
                    <Input
                      id="trancheDescription4"
                      name="trancheDescription4"
                      type="text"
                      required
                      value={data.trancheDescription4}
                      onChange={(event) => setData({...data, trancheDescription4: event.target.value})}
                    />
                    <Label for="trancheDescription4">
                      Descrição da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup floating>
                    <Input
                      id="trancheValue4"
                      name="trancheValue4"
                      type="number"
                      required
                      value={data.trancheValue4}
                      onChange={(event) => setData({...data, trancheValue4: event.target.value})}
                    />
                    <Label for="trancheValue4">
                      Valor da Tranche
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}