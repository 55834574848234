import { User } from "../user"
import { Phase } from "./Phase"

export class Acceleration {
  private _id: string
  public id: string|undefined
  public licensed: User|undefined
  public name: string|undefined
  public phases: Phase[] = []
  public startups: User[] = []
  public mentors: User[] = []
  
  public constructor(data: any={}) {
    this._id = data.id || data._id
    this.id = data.id    
                
    if (data.licensed) {
      this.licensed = new User(data.licensed)
    }
                
    this.name = data.name
                
    if (data.phases) {
      for (const phase of data.phases) {
        this.phases.push(new Phase(phase))
      }
    }
                
        if (data.startups) {
                      for (const startup of data.startups) {
              this.startups.push(new User(startup ))
            }
                  }
                
        if (data.mentors) {
                      for (const mentor of data.mentors) {
              this.mentors.push(new User(mentor))
            }
                  }
              
  }
  
}