import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase2Week4.scss';


export default function Phase2Week4(props: ExerciseProps) {
  const [data, setData] = useState({
    productPrice: props.answer.answer?.productPrice || '',
    businessFormat: props.answer.answer?.businessFormat || '',
    salesGoal: props.answer.answer?.salesGoal || '',
    salesMade: props.answer.answer?.salesMade || '',
  })

  useEffect(() => {
    const isCompleted = data.productPrice.length >= 1 && data.businessFormat.length >= 1 && data.salesGoal.length >= 1 
    && data.salesMade.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Adicione as informações necessárias para a composição de sua estratégia de funil de lançamento
            de produto:
          </h2>
        </div>
        <form className="phase2-week4-form" action="">
          <Row>
            <Col lg={6} md={12}>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                  <Input
                      id="productPrice"
                      name="productPrice"
                      type="number"
                      required
                      value={data.productPrice}
                      onChange={(event) => setData({...data, productPrice: event.target.value})}
                    />
                    <Label for="productPrice">
                      Preço do Produto
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md={10}>
                  <FormGroup floating>
                  <Input
                      id="businessFormat"
                      name="businessFormat"
                      type="text"
                      required
                      value={data.businessFormat}
                      onChange={(event) => setData({...data, businessFormat: event.target.value})}
                    />
                    <Label for="businessFormat">
                      Modelo de Negócio / Formato
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="mt-column" lg={3} md={12}>
              <Row><h3>Meta de Vendas</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="salesGoal"
                      name="salesGoal"
                      type="number"
                      required
                      value={data.salesGoal}
                      onChange={(event) => setData({...data, salesGoal: event.target.value})}
                    />
                    <Label for="salesGoal">
                      Meta de Vendas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col lg={3} md={12}>
              <Row><h3>Vendas Ralizadas</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="salesMade"
                      name="salesMade"
                      type="number"
                      required
                      value={data.salesMade}
                      onChange={(event) => setData({...data, salesMade: event.target.value})}
                    />
                    <Label for="salesMade">
                      Vendas Realizadas
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}