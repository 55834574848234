import { Permission } from "./Permission"

export class Role {
  private _id: string
      public id: string|undefined
      public role: string|undefined
      public code: string|undefined
      public level: number|undefined
      public permissions: Permission[] = []
  
  public constructor(data: any) {
    this._id = data.id || data._id          
    this.id = data.id
            
    this.role = data.role
    this.code = data.code
    this.level = data.level
            
    if (data.permissions) {
      for (const permission of data.permissions) {
        this.permissions.push(new Permission(permission ))
      }
    }
              
  }
}