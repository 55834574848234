import React, { useEffect, useState } from "react"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
  User,
} from "../../../../entities"
import Layout from "../../../../components/layout/Layout"
import Loader from "../../../../components/loader/Loader"
import { UserService } from "../../../../services/UserService"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { GrEdit, GrView } from "react-icons/gr"
import DataTable from "../../../../components/dataTable/DataTable"

export default function ContactListPage() {
  const navigate = useNavigate()

  const [users, setUsers] = useState<User[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("")
  const [typeLabel, setTypeLabel] = useState("")

  useEffect(() => {
    if (window.location.href.includes("/mentor") === true) {
      setType("mentor")
      setTypeLabel("mentor")
    } else if (window.location.href.includes("/investidor") === true) {
      setType("investor")
      setTypeLabel("investidor")
    }

    // eslint-disable-next-line
  }, [window.location.href])

  useEffect(() => {
    if (type !== "") load(1)

    // eslint-disable-next-line
  }, [type])

  const load = async (page = 1) => {
    setLoading(true)
    const roleFilter = new SearchFilter({ key: "roleCode", value: type })
    const params = new SearchParams([roleFilter], 1, 9999)
    const result = await UserService.search(params)

    setUsers(result.data)
    setResultInfo(result.info)
    setLoading(false)
  }

  const renderActions = (params: any) => {
    return (
      <div className="table-action-button-container">
        <Button onClick={() => navigate(`/${typeLabel}/${params.id}`)}>
          <GrEdit />
        </Button>
        {typeLabel === "mentor" && (
          <Button onClick={() => navigate(`/${typeLabel}/view/${params.id}`)}>
            <GrView />
          </Button>
        )}
        {/* <Button onClick={() => deleteItem(params.id)} className="table-action-button"><GrTrash /></Button> */}
      </div>
    )
  }

  const columns = [
    {
      headerName: "Nome",
      renderCell: (params: any) => (
        <>{new User(params).getAttributeValue("name")}</>
      ),
    },
    { field: "email", headerName: "E-mail" },
    {
      headerName: "Documento",
      renderCell: (params: any) => (
        <>{new User(params).getAttributeValue("document")}</>
      ),
    },
    {
      headerName: "Telefone",
      renderCell: (params: any) => (
        <>{new User(params).getAttributeValue("phone")}</>
      ),
    },
    {
      headerName: "Celular",
      renderCell: (params: any) => (
        <>{new User(params).getAttributeValue("mobile")}</>
      ),
    },
    { headerName: "Ações", renderCell: renderActions },
  ]

  return (
    <Layout internal={true}>
      {loading && <Loader />}
      <section className="dashboard-form-page-container">
        <DataTable
          title={typeLabel.charAt(0).toUpperCase() + typeLabel.slice(1)}
          checkboxSelection={false}
          rows={users}
          columns={columns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={loading}
          newLink={`/${typeLabel}/0`}
        />
      </section>
    </Layout>
  )
}
