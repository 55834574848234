import React from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation, Autoplay } from "swiper"
import "swiper/css"
import "swiper/css/navigation"
import "swiper/css/autoplay"

import "./Partners.scss"

export default function Partners() {
  return (
    <section className="partners-container">
      <h2>Parceiros</h2>
      <div className="partners-swiper">
        <Swiper
          spaceBetween={0}
          slidesPerView={3}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          onSlideChange={() => {}}
          onSwiper={(swiper) => {}}
          modules={[Autoplay, Navigation]}
          navigation={true}
          className="partner-carousel"
        >
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-agenciaweber.png"
              alt="Parceiro Agência Weber"
            />
          </SwiperSlide>
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-hacktown.png"
              alt="Parceiro Hacktown"
            />
          </SwiperSlide>
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-lealmetrics.png"
              alt="Parceiro Lealmetrics"
            />
          </SwiperSlide>
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-agenciaweber.png"
              alt="Parceiro Agência Weber"
            />
          </SwiperSlide>
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-hacktown.png"
              alt="Parceiro Hacktown"
            />
          </SwiperSlide>
          <SwiperSlide className="partners-carousel">
            <img
              src="/images/homepage/partners/logo-lealmetrics.png"
              alt="Parceiro Lealmetrics"
            />
          </SwiperSlide>
        </Swiper>
      </div>
    </section>
  )
}
