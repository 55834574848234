import React from "react"
import Layout from "../../../components/layout/Layout"
import UserStorage from "../../../util/userStorage"
import DashboardLicensed from "../../../components/dashboard/licensed/DashboardLicensed"
import DashboardStartup from "../../../components/dashboard/startup/DashboardStartup"

import "./Panel.scss"
import DashboardMentor from "../../../components/dashboard/mentor/DashboardMentor"
import DashboardInvestor from "../../../components/dashboard/investor/DashboardInvestor"
import DashboardMaster from "../../../components/dashboard/admin/DashboardMaster"

export default function DashboardPage() {
  const user = UserStorage.get()

  return (
    <Layout internal={true}>
      <section className="panel-container">
        <section className="panel-dashboard-container">
          {user?.role?.code === "admin" && <DashboardMaster />}
          {user?.role?.code === "licensed" && <DashboardLicensed />}
          {(user?.role?.code === "startup" ||
            user?.role?.code === "founder") && <DashboardStartup />}
          {user?.role?.code === "mentor" && <DashboardMentor />}
          {user?.role?.code === "investor" && <DashboardInvestor />}
        </section>
      </section>
    </Layout>
  )
}
