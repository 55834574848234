import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week4.scss';


export default function Phase3Week4(props: ExerciseProps) {
  const [data, setData] = useState({
    ticket: props.answer.answer?.ticket || '',
    markup: props.answer.answer?.markup || '',
    cac: props.answer.answer?.cac || '',
    ltv: props.answer.answer?.ltv || '',
  })

  useEffect(() => {
    const isCompleted = data.ticket.length >= 1 && data.markup.length >= 1 && 
    data.cac.length >= 1 && data.ltv.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase3-week4-form" action="">
          <Row>
            <Col md={3}>
              <Row><h3>TICKET</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="ticket"
                      name="ticket"
                      type="number"
                      required
                      value={data.ticket}
                      onChange={(event) => setData({...data, ticket: event.target.value})}
                    />
                    <Label for="ticket">
                      Ticket
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row><h3>MARKUP</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="markup"
                      name="markup"
                      type="number"
                      required
                      value={data.markup}
                      onChange={(event) => setData({...data, markup: event.target.value})}
                    />
                    <Label for="markup">
                      Markup
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row><h3>CAC</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="cac"
                      name="cac"
                      type="number"
                      required
                      value={data.cac}
                      onChange={(event) => setData({...data, cac: event.target.value})}
                    />
                    <Label for="cac">
                      CAC
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Row><h3>LTV</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="ltv"
                      name="ltv"
                      type="number"
                      required
                      value={data.ltv}
                      onChange={(event) => setData({...data, ltv: event.target.value})}
                    />
                    <Label for="ltv">
                      LTV
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}