import { hookstate, useHookstate } from '@hookstate/core';

const whitelabelState: any = hookstate(null);
const stateWrapper = (state: any) => ({
  get: (): any => state?.value,
  set: (content: any) => state?.set((c: any) => c = content),
});

export const accessWhitelabel = () => stateWrapper(whitelabelState);
export const useWhitelabel = () => stateWrapper(useHookstate(whitelabelState));
