import React from 'react';
import Layout from '../../components/layout/Layout';
import HelpCenter from '../../components/helpCenter/HelpCenter';

import './HelpCenterPage.scss';


export default function HelpCenterPage() { 
  return (
    <Layout internal={true}>
      <div className="help-center-page-container">
        <HelpCenter />
      </div>
    </Layout>
  )
}