import { Pagination, PaginationItem, PaginationLink } from "reactstrap";

import './DataTablePagination.scss'

export interface PaginationProps {
  page: number
  pages: number
  pageSize: number
  count: number
  onPageChange?: (page: number) => void  
}

export default function DataTablePagination(props: PaginationProps) {
  const changePage = (newPage: number) => {
    if (props?.onPageChange)
      props.onPageChange(newPage)
  }

  return (
    <Pagination aria-label="">
      <PaginationItem disabled={props.page === 1}>
          <PaginationLink first onClick={() => changePage(1)}/>
        </PaginationItem>
        <PaginationItem disabled={props.page === 1}>
          <PaginationLink previous onClick={() => changePage(props.page - 1)} />
        </PaginationItem>
        {Array(props.pages).fill(0).map((element, index) => {
          let page = index + 1
          return (
            <PaginationItem key={`pagingation-${page}`} active={props.page === page}>
              <PaginationLink onClick={() => changePage(page)}>
                {page}
              </PaginationLink>
            </PaginationItem>
          )
        })}
        <PaginationItem disabled={props.page === props.pages}>
          <PaginationLink next onClick={() => changePage(props.page + 1)} />
        </PaginationItem>
        <PaginationItem disabled={props.page === props.pages}>
          <PaginationLink last onClick={() => changePage(props.pages)} />
        </PaginationItem>

    </Pagination>
  )
}