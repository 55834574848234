import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week2.scss';


export default function Phase3Week2(props: ExerciseProps) {
  const [data, setData] = useState({
    erpAccessLink: props.answer.answer?.erpAccessLink || '',
  })

  useEffect(() => {
    const isCompleted = data.erpAccessLink.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Siga os passos a seguir e preencha os campos conforme solicitado para registrarmos a atividade:
          </h2>
        </div>
        <form className="phase3-week2-form" action="">
          <Row>
            <Col md={9}>
              <FormGroup floating>
              <Input
                  id="erpAccessLink"
                  name="erpAccessLink"
                  type="text"
                  required
                  value={data.erpAccessLink}
                  onChange={(event) => setData({...data, erpAccessLink: event.target.value})}
                />
                <Label for="erpAccessLink">
                  Insira aqui o link de acesso ao seu ERP
                </Label>
              </FormGroup>
            </Col>
            <Col  md={3}>
              <button
                onClick={() => {}}
              >
                Acessar Tutorial
              </button>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}