import React, { useEffect } from "react"
import { BrowserRouter } from "react-router-dom"
import Router from "./pages/Router"
import "bootstrap/dist/css/bootstrap.min.css"
import "./App.scss"
import theme from "./Themes"
import { WhiteLabelService } from "./services/whitelabel/WhiteLabelService"
import { useWhitelabel } from "./stores/WhiteLabelStore"

export default function App() {
  const whitelabel = useWhitelabel().get()

  const root = document.documentElement
  root.style.setProperty("--color-primary", whitelabel?.colors?.primary || theme.color.primary)
  root.style.setProperty("--color-secondary", whitelabel?.colors?.secondary || theme.color.secondary)
  root.style.setProperty("--color-tertiary", whitelabel?.colors?.tertiary || theme.color.tertiary)
  root.style.setProperty("--color-quaternary", whitelabel?.colors?.quaternary || theme.color.quaternary)
  root.style.setProperty("--color-quinary", whitelabel?.colors?.quinary || theme.color.quinary)
  root.style.setProperty("--color-senary", whitelabel?.colors?.senary || theme.color.senary)
  root.style.setProperty("--color-septenary", whitelabel?.colors?.septenary || theme.color.septenary)
  root.style.setProperty("--color-octonary", whitelabel?.colors?.octanary || theme.color.octonary)
  root.style.setProperty("--color-nonary", whitelabel?.colors?.nonary || theme.color.nonary)
  root.style.setProperty("--color-denary", whitelabel?.colors?.denary || theme.color.denary)
  root.style.setProperty("--color-undenary", whitelabel?.colors?.undenary || theme.color.undenary)
  root.style.setProperty("--color-duodenary", whitelabel?.colors?.duodenary || theme.color.duodenary)
  root.style.setProperty("--color-tredenary", whitelabel?.colors?.tredenary || theme.color.tredenary)
  // root.style.setProperty("--color-quattuordenary", theme.color.quattuordenary)

  useEffect(() => {
    WhiteLabelService.getConfigs()
  }, [])

  return (
    <BrowserRouter>
      <div className="App">
        <Router />
      </div>
    </BrowserRouter>
  )
}
