import React, { useState } from "react"
import UserStorage from "../../util/userStorage"
import { Link, useNavigate } from "react-router-dom"
import { AuthenticationService } from "../../services/AuthenticationService"
import { Form, FormGroup, Input, Label, Button } from "reactstrap"
import { Image } from "@chakra-ui/react"

import "./Login.scss"

import { useWhitelabel } from "../../stores/WhiteLabelStore"

export default function Login() {
  const whitelabel = useWhitelabel().get()

  const authenticationService = new AuthenticationService()
  const navigate = useNavigate()

  const [data, setData] = useState({
    email: "",
    password: "",
  })
  const [errorMessage, setErrorMessage] = useState("")

  const login = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setErrorMessage("")

    if (!data.email) {
      setErrorMessage("Digite o e-mail")
      return
    }

    if (!data.password) {
      setErrorMessage("Digite a senha")
      return
    }

    try {
      const token = await authenticationService.login(data.email, data.password)
      UserStorage.setToken(token)
      navigate("/painel")
    } catch (error: any) {
      setErrorMessage(error.message)
    }
  }

  return (
    <section className="form-page-container">
      <div className="login-form-container">
        <div className="logo">
          <Link to="/">
            <Image
              mb="2rem"
              src={whitelabel?.logo?.url}
              alt="Logo da Valide.se"
            />
          </Link>
        </div>
        <div className="title">
          <h1>Faça seu Login</h1>
        </div>
        {errorMessage && (
          <div className="error-message">
            <span>{errorMessage}</span>
          </div>
        )}
        <Form>
          <FormGroup floating>
            <Input
              id="email"
              name="email"
              placeholder="Email"
              type="email"
              required
              onChange={(event) =>
                setData({ ...data, email: event.target.value })
              }
            />
            <Label for="email">Email</Label>
          </FormGroup>{" "}
          <FormGroup floating>
            <Input
              id="password"
              name="password"
              placeholder="Senha"
              type="password"
              required
              onChange={(event) =>
                setData({ ...data, password: event.target.value })
              }
            />
            <Label for="password">Senha</Label>
          </FormGroup>{" "}
          <Button onClick={login} type="submit">
            Acessar
          </Button>
        </Form>
        <div className="forgot-password">
          <Link to="/recuperar-senha">Esqueci minha senha</Link>
        </div>
        <div className="create-account">
          <span>Não possui uma conta?</span>
          <Link to="/cadastrar">Cadastre-se</Link>
        </div>
      </div>
      <div className="login-banner-container">
        <img
          src="/images/login-startups.jpg"
          alt="Banner do formulário da Valide.se"
        />
      </div>
    </section>
  )
}
