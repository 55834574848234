import { SiteContact } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class SiteRepository {
  public static async contact(contact: SiteContact): Promise<void> {
    
    await client.DoRequest('POST', '/site/contact', contact)
  }
}
