import React, { useState } from "react"
import { Link } from "react-router-dom"
import { AuthenticationService } from "../../services/AuthenticationService"
import Loader from "../../components/loader/Loader"
import { Form, FormGroup, Input, Label, Button } from "reactstrap"
import { Image } from "@chakra-ui/react"

import "./Password.scss"

import { useWhitelabel } from "../../stores/WhiteLabelStore"

export default function Password() {
  const whitelabel = useWhitelabel().get()

  const authenticationService = new AuthenticationService()

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const save = async (e: React.SyntheticEvent) => {
    e.preventDefault()
    setIsLoading(true)
    const success = await authenticationService.forgotPassword(email)
    if (success === true) {
      setMessage(
        "Em alguns instantes você receberá um e-mail com um link para recuperar a sua senha."
      )
    } else {
      setMessage("E-mail não cadastrado!")
    }

    setIsLoading(false)
    return
  }

  return (
    <section className="form-page-container">
      <div className="password-container">
        <div className="logo">
          <Link to="/">
            <Image
              mb="2rem"
              src={whitelabel?.logo?.url}
              alt="Logo da Valide.se"
            />
          </Link>
        </div>
        <div className="title">
          <h1>Recupere sua Senha</h1>
        </div>
        {isLoading && <Loader />}
        {message !== "" ? (
          <div className="message">
            <span>{message}</span>
          </div>
        ) : (
          <Form>
            <FormGroup floating>
              <Input
                id="email"
                name="email"
                placeholder="Email"
                type="email"
                required
                onChange={(event) => setEmail(event.target.value)}
              />
              <Label for="email">Email</Label>
            </FormGroup>{" "}
            <Button onClick={save} type="button">
              Recuperar Senha
            </Button>
          </Form>
        )}
        <div className="return-to-login-page">
          <span>Deseja fazer login?</span>
          <Link to="/login">Faça seu login</Link>
        </div>
      </div>
      <div className="password-banner-container">
        <img
          src="/images/login-startups.jpg"
          alt="Banner do formulário da Valide.se"
        />
      </div>
    </section>
  )
}
