export class ExerciseType {
  private _id: string
      public id: string|undefined
      public name: string|undefined
      public code: number|undefined
  
  public constructor(data: any) {
    this._id = data.id || data._id
          
        this.id = data.id
                
        this.name = data.name
                
        this.code = data.code
              
  }
}