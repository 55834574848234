import React, { useState } from "react";
import {
  UncontrolledAccordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

import "./HelpCenter.scss";

export default function HelpCenter() {
  const [open, setOpen] = useState("");
  const toggle = (id: any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="help-center">
      <div className="title">
        <h1>Central de Ajuda</h1>
      </div>
      <div className="help-center-accordion">
        {/* <UncontrolledAccordion defaultOpen={["1"]} stayOpen toggle={toggle}> */}
        <UncontrolledAccordion
          defaultOpen={["1"]}
          stayOpen
          toggle={() => console.log("teste")}
        >
          <AccordionItem>
            <AccordionHeader targetId="1">
              Como minha startup pode participar de um programa de aceleração
              Valide-se?
            </AccordionHeader>
            <AccordionBody accordionId="1">
              Os editais são abertos em todos os semestres nas cidades onde
              estão os nossos licenciados apresentados neste link.
              <br />
              Acompanhe as redes sociais do licenciado mais próximo a você para
              receber notícias dos editais e inscreva-se!
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="2">
              A Valide-se investe nas startups aceleradas?
            </AccordionHeader>
            <AccordionBody accordionId="2">
              Algumas das startups aceleradas podem receber propostas de
              investimentos da Valide-se e de seus licenciados a partir do
              momento da inscrição.
              <br />
              Estas propostas são feitas considerando a maturidade da startup, o
              mercado no qual ela está inserida, sua estrutura societária e o
              volume de capital disponível em nossas carteiras para o segmento
              da solução.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="3">
              Posso participar remotamente dos programas?
            </AccordionHeader>
            <AccordionBody accordionId="3">
              Nossos programas são híbridos e possuem agendas obrigatoriamente
              presenciais, bem como as agendas remotas. A startup ingressante
              deve estar ciente das obrigações de agendas e a ausência em 25% do
              cronograma gera a quebra do contrato, a descontinuação da startup
              no programa e possível multa no caso de a startup já ter recebido
              algum aporte do licenciado ou da matriz Valide-se.
            </AccordionBody>
          </AccordionItem>
          <AccordionItem>
            <AccordionHeader targetId="4">
              Quantos projetos posso inscrever num edital de chamada?
            </AccordionHeader>
            <AccordionBody accordionId="4">
              Pelo fato de selecionarmos também startups em fase de ideação,
              você pode inscrever quantos projetos quiser numa mesma chamada,
              mas, nossos licenciados selecionam apenas um projeto para cada
              fundador e os nomes dos fundadores selecionados constam em nosso
              sistema para que não possam inscrever mais de um projeto em outros
              programas de outros licenciados.
            </AccordionBody>
          </AccordionItem>
        </UncontrolledAccordion>
      </div>
    </section>
  );
}
