export class SiteContact {
  public name = ''
  public company = ''
  public phone = ''
  public email = ''
  public message = ''

  constructor(data: any) {
    this.name = data.name || ''
    this.company = data.company || ''
    this.phone = data.company || ''
    this.email = data.email || ''
    this.message = data.message || ''
  }
}