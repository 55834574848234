import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase3Week1.scss';


export default function Phase3Week1(props: ExerciseProps) {
  const [data, setData] = useState({
    technologyType1: props.answer.answer?.technologyType1 || '',
    technologyType2: props.answer.answer?.technologyType2 || '',
    technologyType3: props.answer.answer?.technologyType3 || '',
    
    insertedMarket1: props.answer.answer?.insertedMarket1 || '',
    insertedMarket2: props.answer.answer?.insertedMarket2 || '',
    insertedMarket3: props.answer.answer?.insertedMarket3 || '',

    startupMaturity: props.answer.answer?.startupMaturity || '',
  })

  useEffect(() => {
    const isCompleted = data.technologyType1.length >= 1 && data.technologyType2.length >= 1 && 
    data.technologyType3.length >= 1 && data.insertedMarket1.length >= 1 && data.insertedMarket2.length >= 1 && 
    data.insertedMarket3.length >= 1 && data.startupMaturity.length >= 1

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
          <h2>Selecione até três itens de cada um dos filtros abaixo de acordo com o que mais se enquadra
            à realidade de sua Startup atualmente:
          </h2>
        </div>
        <form className="phase3-week1-form" action="">
          <Row>
            <Col className="mt-column" md={4}>
              <Row><h3>Tecnologia</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="technologyType1"
                      name="technologyType1"
                      type="text"
                      required
                      value={data.technologyType1}
                      onChange={(event) => setData({...data, technologyType1: event.target.value})}
                    />
                    <Label for="technologyType1">
                      Tipo de Tecnologia
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="technologyType2"
                      name="technologyType2"
                      type="text"
                      required
                      value={data.technologyType2}
                      onChange={(event) => setData({...data, technologyType2: event.target.value})}
                    />
                    <Label for="technologyType2">
                      Tipo de Tecnologia
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                  <Input
                      id="technologyType3"
                      name="technologyType3"
                      type="text"
                      required
                      value={data.technologyType3}
                      onChange={(event) => setData({...data, technologyType3: event.target.value})}
                    />
                    <Label for="technologyType3">
                      Tipo de Tecnologia
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="mt-column" md={4}>
              <Row><h3>Mercado</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="insertedMarket1"
                      name="insertedMarket1"
                      type="text"
                      required
                      value={data.insertedMarket1}
                      onChange={(event) => setData({...data, insertedMarket1: event.target.value})}
                    />
                    <Label for="insertedMarket1">
                      Mercado Inserido
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="insertedMarket2"
                      name="insertedMarket2"
                      type="text"
                      required
                      value={data.insertedMarket2}
                      onChange={(event) => setData({...data, insertedMarket2: event.target.value})}
                    />
                    <Label for="insertedMarket2">
                      Mercado Inserido
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="insertedMarket3"
                      name="insertedMarket3"
                      type="text"
                      required
                      value={data.insertedMarket3}
                      onChange={(event) => setData({...data, insertedMarket3: event.target.value})}
                    />
                    <Label for="insertedMarket3">
                      Mercado Inserido
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
            <Col className="mt-column" md={4}>
              <Row><h3>Maturidade</h3></Row>
              <Row>
                <Col>
                  <FormGroup floating>
                    <Input
                      id="startupMaturity"
                      name="startupMaturity"
                      type="text"
                      required
                      value={data.startupMaturity}
                      onChange={(event) => setData({...data, startupMaturity: event.target.value})}
                    />
                    <Label for="startupMaturity">
                      Maturidade da Startup
                    </Label>
                  </FormGroup>
                </Col>
              </Row>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}