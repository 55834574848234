import React, { useState }  from 'react';
import Layout from '../../components/layout/Layout';
import {
  Form, Row, Col, FormGroup, Input, Label, Button
} from 'reactstrap';

import '../../components/profileForms/ProfileForms.scss';
import '../dashboard/Dashboard.scss';
import { AuthenticationService } from '../../services/AuthenticationService';
import UserStorage from '../../util/userStorage';
//import { User } from '../../../entities';


export default function ChangePassword() {
  const [form, setForm] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: ''
  })

  const save = async (event :React.SyntheticEvent) => {
    event.preventDefault()

    if (form.newPassword.length < 6) {
      alert("A nova senha deve ter pelo menos 6 caracteres.")
      return false
    }

    if (form.newPassword !== form.newPasswordConfirmation) {
      alert("A nova senha e a confirmação devem ser iguais.")
      return false
    }
    
    try {
      const token = UserStorage.getToken()

      const authService = new AuthenticationService()
      await authService.changePassword(token, form.currentPassword, form.newPassword, form.newPasswordConfirmation)
      alert("Senha alterada com sucesso!")
    } catch (error: any) {
      if (error?.message) {
        alert(error.message)
      }
    }

    return false
  }

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>Alterar Senha</h1>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Digite a sua senha atual"
                  type="password"
                  required
                  value={form.currentPassword}
                  onChange={(event) => setForm({...form, currentPassword: event.target.value})}
                />
                <Label for="currentPassword">
                  Senha Atual
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="newPassword"
                  name="newPassword"
                  placeholder="Digite a sua nova senha"
                  type="password"
                  required
                  value={form.newPassword}
                  onChange={(event) => setForm({...form, newPassword: event.target.value})}
                />
                <Label for="newPassword">
                  Nova Senha
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="newPasswordConfirmation"
                  name="newPasswordConfirmation"
                  placeholder="Digite a confirmação da sua nova senha"
                  type="password"
                  required
                  value={form.newPasswordConfirmation}
                  onChange={(event) => setForm({...form, newPasswordConfirmation: event.target.value})}
                />
                <Label for="newPasswordConfirmation">
                  Confirmação da Nova Senha
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Button
            onClick={save} type="submit"
          >
            Alterar Senha
          </Button>
        </Form>
      </section>
    </Layout>
  );
}