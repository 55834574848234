import React from "react"
import { Flex, Text } from "@chakra-ui/react"

import { useWhitelabel } from "../../../stores/WhiteLabelStore"

export default function DashboardStartup() {
  const whitelabel = useWhitelabel().get()

  return (
    <Flex
      w="100%"
      bg={whitelabel?.colors?.quinary}
      display="flex"
      justifyContent="center"
      fontFamily="korto-book"
    >
      <Flex w="100%" maxWidth="1440px" p="1rem" direction="column">
        <Text
          color={whitelabel?.colors?.quinary}
          fontSize={{ base: "1.5rem", sm: "2rem", md: "2rem", lg: "2rem" }}
          fontWeight="600"
          alignSelf="center"
          bg={whitelabel?.colors?.secondary}
          p="0.25rem 1.5rem"
          borderRadius="0.5rem"
        >
          Dashboard da Startup
        </Text>

        <Flex direction="column" my="2rem">
          <Text
            p="0.25rem 1rem"
            borderRadius="0.5rem"
            color={whitelabel?.colors?.quinary}
            bg={whitelabel?.colors?.quaternary}
            fontSize="1.5rem"
            fontWeight="600"
            alignSelf="center"
          >
            Informações da Startup
          </Text>
          <Flex
            flexWrap="wrap"
            justifyContent={{
              base: "center",
              sm: "space-between",
            }}
            gap="1rem"
          >
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Evolução no Programa
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                Em Andamento
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Valuation no Início do Programa
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 3.891.640,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Valuation Atual
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 6.722.343,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Total Aportado pela Carteira
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 2.072.000,00
              </Text>
            </Flex>
            <Flex
              direction="column"
              w="100%"
              maxW="250px"
              minH="150px"
              px="0.5rem"
              justifyContent="space-between"
            >
              <Text
                color={whitelabel?.colors?.secondary}
                fontSize="1.25rem"
                fontWeight="600"
              >
                Outros Aportes
              </Text>
              <Text
                p="0.25rem"
                bg={whitelabel?.colors?.primary}
                color={whitelabel?.colors?.quinary}
                borderRadius="2.5rem"
                fontSize="1.25rem"
                fontWeight="600"
              >
                R$ 115.500,00
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}
