import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';

import './PainMap.scss';
import { Answer } from '../../../entities';

export default function PainMap(props: ExerciseProps) {
  const [startupMap, setStartupMap] = useState(props.answer.answer?.startupMap || '')
  const [competitorMap, setCompetitorMap] = useState(props.answer.answer?.competitorMap || '')

  useEffect(() => {
    const isCompleted = startupMap.length >= 3 && competitorMap.length >= 3
    const answer = new Answer({
      ...props.answer, 
      answer: {
        startupMap,
        competitorMap
      },
      isCompleted
    })
    
    props.save(answer)
    // eslint-disable-next-line
  }, [startupMap, competitorMap])
  
  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="pain-map-form" action="">
          <Row>
            <Col md={6}>
              <div className="pain-map-form-field">
                <Link to="/" target="_blank">Modelo de Mapa da Dor</Link>
                <FormGroup floating>
                  <Input
                    name="startupMap"
                    type="text"
                    placeholder="Insira o link do seu Mapa da Dor"
                    required
                    value={startupMap}
                    onChange={(event) => setStartupMap(event.target.value)}
                  />
                  <Label for="inputLink1">
                    Insira o link do seu Mapa da Dor
                  </Label>
                </FormGroup>
              </div>
            </Col>
            <Col md={6}>
              <div className="pain-map-form-field">
                <Link to="/" target="_blank">Modelo de Mapa da Concorrência</Link>
                <FormGroup floating>
                  <Input
                    id="inputLink2"
                    name="competitorMap"
                    type="text"
                    placeholder="Insira o link do seu Mapa da Concorrência"
                    required
                    value={competitorMap}
                    onChange={(event) => setCompetitorMap(event.target.value)}
                  />
                  <Label for="inputLink2">
                    Insira o link do seu Mapa da Concorrência
                  </Label>
                </FormGroup>
              </div>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}