import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../../../../components/layout/Layout";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { User } from "../../../../entities";
import UserStorage from "../../../../util/userStorage";
import { UserService } from "../../../../services/UserService";

import "../../Dashboard.scss";

export default function LicensedFormPage() {
  const params = useParams();
  const navigate = useNavigate();

  const [user, setUser] = useState(
    new User({
      role: { code: "licensed" },
    })
  );

  useEffect(() => {
    if (params?.id === "me") {
      const user = UserStorage.get();
      if (user?.role?.code !== "licensed") return;
      if (!user?.id) return;
      load(user.id);
    } else if (params?.id !== "0") {
      if (!params.id) return;
      load(params.id);
    }

    // eslint-disable-next-line
  }, [params]);

  const load = async (id: string) => {
    const user = await UserService.get(id);
    console.log("user", user);
    setUser(user);
  };

  const save = async (event: any) => {
    event.preventDefault();

    if (!user.id) {
      if (user.password?.length < 6) {
        alert("A senha deve ter pelo menos 6 caracteres.");
        return false;
      }
    }

    await UserService.save(user);
    navigate("/licenciado");

    return false;
  };

  const changeFieldValue = (code: string, value: any) => {
    const userNew = new User(user);
    userNew.setAttributeValue(code, value);
    setUser(userNew);
  };

  return (
    <Layout internal={true}>
      <section className="profile-forms">
        <Form onSubmit={save}>
          <Row>
            <Col md={12}>
              <div className="title">
                <h1>Cadastro de Licenciado</h1>
              </div>
            </Col>
          </Row>
          {user?.role?.code === "licensed" && (
            <Row>
              <Col md={12}>
                <div className="title">
                  <h2>
                    {window.location.origin}/cadastrar?aceleradora={user.id}
                  </h2>
                </div>
              </Col>
            </Row>
          )}
          <Row>
            <Col md={12}>
              <div className="sub-title">
                <h2>Informações de acesso</h2>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="name"
                  name="name"
                  placeholder="Digite o nome completo"
                  type="text"
                  required
                  value={user.getAttributeValue("name")}
                  onChange={(event) =>
                    changeFieldValue("name", event.currentTarget.value)
                  }
                />
                <Label for="name">Nome completo</Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="email"
                  name="email"
                  placeholder="Digite o email"
                  type="email"
                  required
                  value={user.email}
                  onChange={(event) =>
                    setUser(
                      new User({ ...user, email: event.currentTarget.value })
                    )
                  }
                />
                <Label for="email">Email</Label>
              </FormGroup>
            </Col>
          </Row>
          {!user.id && (
            <Row>
              <Col md={6}>
                <FormGroup floating>
                  <Input
                    id="password"
                    name="password"
                    placeholder="Digite a senha"
                    type="password"
                    value={user.password}
                    onChange={(event) =>
                      setUser(
                        new User({
                          ...user,
                          password: event.currentTarget.value,
                        })
                      )
                    }
                  />
                  <Label for="password">Senha</Label>
                </FormGroup>
              </Col>
            </Row>
          )}
          <Button type="submit">Salvar</Button>
        </Form>
      </section>
    </Layout>
  );
}
