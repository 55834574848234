import { BusinessCategory, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class BusinessCategoryRepository {
  public static async search(params: SearchParams, token: string): Promise<SearchResult<BusinessCategory>> {
    const headers = { Authorization: token };
    const response = await client.DoRequest('GET', `/businessCategory?page=${params.page}&limit=${params.limit}`, {}, headers)

    const result = new SearchResult<BusinessCategory>(response.data, response.info)

    return result
  }
}