import React from 'react';

import './Report.scss';


export default function Report() {
  return (
    <section className="report-container">
      <div className="content">
        <div className="title">
          <h1>BIOSPHERE USJT</h1>
          <h2>Ciclo 1</h2>
        </div>
        <div className="presentation">
          <h2>APRESENTAÇÃO</h2>
          <span>
            O 1º Programa de Aceleração do hub Biosphere, localizado à
            Rua Taquari, 546, Andar Intermediário, Mooca, São Paulo - SP,
            apresenta as startups participantes e com orgulho, apresenta
            também seus resultados e evoluções na Fase 1 do programa.
            <br />
            Foram selecionadas 14 startups ao programa, sendo elas das
            verticais de: PetTech; PropTech; Cannabis; EdTech; FinTech;
            MarTech; Mobility; HardTech; SocialTech; Management, e;
            ContentTech.
            <br />
            Ao todo, 11 startups permanecem no Programa de Aceleração.
            Nas páginas a seguir, apresentamos seus respectivos
            desempenhos durante o Programa.
          </span>
        </div>
        <div className="startups-container">
          <div className="startup">
            <div className="title">
              <h2>AURIA</h2>
            </div>
            <div className="description">
              <span>
                Através de um sistema automatizado, a startup propõe-se a
                analisar todos os dados colhidos nas campanhas de marketing
                de uma companhia, tratá-los e dar insights de campanhas e
                ações a partir destes dados.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Início do desenvolvimento sem custo, da versão Beta da plataforma</li>
                  <li>Início de programa de captação de recurso público</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>RHUNA</h2>
            </div>
            <div className="description">
              <span>
                A startup propõe-se a solucionar o problema da demanda por
                remanejamento de mão de obra intelectual em projetos, nos
                casos de empresas que são contratadas para executar mais de
                um projeto e/ou atendimento de uma determinada área de
                especialização.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Início do desenvolvimento sem custo, da versão Beta da plataforma</li>
                  <li>Início de programa de captação de recurso público</li>
                  <li>Adição de solução complementar de entrada no mercado (Kanban)</li>
                  <li>Integração com plataforma CRM já consolidada no mercado</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>SMARTABLE</h2>
            </div>
            <div className="description">
              <span>
                Sistema de cardápios digitais disponíveis em tablets, com
                integração de pagamentos e sugestões de opções
                complementares aos clientes do estabelecimento.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Início do desenvolvimento sem custo, da versão Beta da plataforma</li>
                  <li>Primeiras vendas realizadas</li>
                  <li>Comprovação de aumento médio de 10% no ticket por mesa instalada</li>
                  <li>Definição de fornecedor do Hardware durante o MVP</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>
          
          <div className="startup">
            <div className="title">
              <h2>BRASIL CANN</h2>
            </div>
            <div className="description">
              <span>
                Tem a proposta de auxiliar as associações e institutos que
                viabilizam o acesso a tratamentos de doenças degenerativas e
                autoimunes a beneficiar seus associados e pacientes no acesso
                aos medicamentos a base de Canabidiol, no processo jurídico,
                na formatação de carteira de fornecedores e no acesso a
                pesquisas do setor.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Primeiras vendas realizadas</li>
                  <li>Parceria com fornecedores internacionais do oleo de canabidiol</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>PETS BUNKER</h2>
            </div>
            <div className="description">
              <span>
                A startup criou e vendeu (ainda antes de ingressar no
                programa), casas para pets com isolamento acústico,
                refrigeração, vigilância por câmera e até mesmo com a
                possibilidade de dispenser de água, para animais que passam
                por estresse ocasionado por fogos de artifício, som alto,
                eventos sociais, etc.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Início do desenvolvimento de projeto de produção descentralizada e distribuição através dos fablabs das instituições de ensino</li>
                  <li>Testes com clínica veterinária da universidade</li>
                  <li>Validação de nova aplicação (pós operatório)</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 1.021.158,45</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>81,3%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>SHARE COMICS</h2>
            </div>
            <div className="description">
              <span>
                Software que visa a melhoria da experiência de leitura do
                público jovem, tornando-a mais dinâmica através de efeitos de
                animação, em um formato adequado ao mobile, focando
                inicialmente no mercado de HQs.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Mais de 40 quadrinistas interessados em publicar</li>
                  <li>Início do desenvolvimento do software (baixa fidelidade)</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>DINDIN VERDE</h2>
            </div>
            <div className="description">
              <span>
                Startup que pretende premiar clientes consumidores de
                produtos de beleza, estética e cuidado pessoal através da
                reposição de embalagens destes produtos em pontos de coleta
                pré cadastrados.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Cadastro de interessados na aquisição</li>
                  <li>Primeiras vendas realizadas</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 867.984,68</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>78%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>MUTABILIS</h2>
            </div>
            <div className="description">
              <span>
                A startup propõe-se a reduzir os erros nas decisões de
                investimento em Day Trade, eliminando as decisões emocionais
                através de robôs de investimento com capacidade de operar B3
                e Forex.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Aplicação e teste de modelo com público selecionado</li>
                  <li>Cadastro de centenas de interessados na aquisição</li>
                  <li>Início do desenvolvimento sem custo, da versão Beta da plataforma</li>
                  <li>Primeiras operações de Forex já realizadas com resultados positivos</li>
                  <li>Integrações programadas com 3 corretoras de grande porte a partir da constituição do CNPJ</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 190.956,63</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 1.021.158,45</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>81,3%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>PLEI</h2>
            </div>
            <div className="description">
              <span>
                Startup do setor de educação que cria laboratórios de realidade
                aumentada que complementam as atividades curriculares de
                uma instituição de ensino, permitindo a aplicação de disciplinas
                que até então seriam impossíveis de serem aplicadas por
                questões legais e/ou estruturais.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Remodelagem comercial</li>
                  <li>Abertura de processo comercial em pelo menos 4 grandes instituições de ensino médio e/ou profissionalizante</li>
                  <li>Ingresso em programa de pesquisa</li>
                  <li>Abertura de negociação com investidores privados</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 149.155,80</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 2.000.000,00</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>80%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>ZION</h2>
            </div>
            <div className="description">
              <span>
                Startup de veículos elétricos para utilização em vias internas,
                transportando passageiros, pequenas cargas e puxando
                carreta. A capacidade total é de 1.000kg de carga + 1.000kg de
                puxo.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Mapeamento de clientes interessados na solução</li>
                  <li>Primeiras cartas de intenção de aquisição</li>
                  <li>Evolução do projeto de carenagem</li>
                  <li>Montagem do prototipo funcional em andamento</li>
                  <li>Abertura de negociação com investidores privados</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 159.955,80</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 20.000.000,00</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>97%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="startup">
            <div className="title">
              <h2>VIVALISTO</h2>
            </div>
            <div className="description">
              <span>
                Ecossistema de serviços e produtos diversos disponíveis em
                planos para corretores imobiliários independentes e pequenas
                imobiliárias. São mais de 2.000 usuários e um ticket médio de
                R$50,00.
              </span>
            </div>
            <div className="information">
              <div className="evolutions">
                <h3>Evoluções desde o ingresso no programa:</h3>
                <ul>
                  <li>Ingresso no programa de pesquisa</li>
                  <li>Redução dos custos através do consumo de serviços da carteira fornecida pelo hub</li>
                </ul>
              </div>
              <div className="financial">
                <ul>
                  <li>Aporte Realizado: <strong>R$ 148.500,00</strong></li>
                  <li>Valuation no Ingresso: <strong>R$ 40.000.000,00</strong></li>
                  <li>Valuation Atual: <strong>em auditagem</strong></li>
                  <li>Captable Com os Fundadores: <strong>84%</strong></li>
                </ul>
                <div className="company-logo">
                  <img
                    src="/images/validese-logo-original.png"
                    alt="Logo da Valide.se"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="special-thanks">
          <h2>AGRADECIMENTOS</h2>
          <span>
            É com enorme prazer que agradecemos a todos os parceiros de nosso
            ecossistema e aos membros dos órgãos dos setores público e privado
            que têm tornado nosso trabalho possível.
            <br />
            Agradecemos às comunidades de startups e ecossistemas de
            inovação pela parceria na realização de eventos e mentorias às
            startups participantes de nosso hub, ao Grupo Ânima e a
            Universidade São Judas Tadeu, pelo acesso aos professores e
            coordenadores que tanto se empenharam em atender as startups e
            conectá-las com os laboratórios e equipes quando cabível.
            <br />
            Nossos agradecimentos especiais à equipe da Universidade São Judas
            Tadeu, aos representantes das empresas que compõem as posições
            acionárias de nossa carteira e aos mentores que todos os dias
            dedicam seus tempos e suas agendas ao programa.
          </span>
        </div>
      </div>
    </section>
  );
}