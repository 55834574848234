import React from 'react'

import './Loader.scss'

export default function Loader() {
    return (
        <div className='loader-overlay'>
            <div className='inner'>
                <div className='content'>
                <span className="spinner" />
                </div>
            </div>
        </div>
    )
}