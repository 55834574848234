import React from 'react';
import { Link } from 'react-router-dom';
import { AiOutlineWhatsApp, AiOutlineCopyrightCircle, AiFillHeart } from "react-icons/ai";

import './Footer.scss';
import { useWhitelabel } from '../../stores/WhiteLabelStore';


export default function Footer() {
  const whitelabel = useWhitelabel().get()

  const scrollSection = (id: string) => {
    console.log(window.location.pathname);
    if (window.location.pathname === '/') {
      const section = document.querySelector(`#${id}`);
      section?.scrollIntoView({ behavior: 'smooth' });
      return false;
    }
  };

  return (
    <footer id="footer">
      <div className="footer-rocket-icon">
        <img src="/images/homepage/rocket-footer.png" alt="Ícone de Foguete" />
      </div>
      <section className="title">
        <h1>Aceleração, comunidade e expansão. . .</h1>
      </section>
      <section className="content">
        {whitelabel?.home === true && (
        <>
        <div className="divisor"></div>
        <div className="links">
          <div className="logo">
            <img src="/images/icon-validese.png" alt="Logo da Valide.se"/>
          </div>
          <div className="list-container">
            <ul>
              <li>Tel.:</li>
              <li><a className="whatsapp" href="https://api.whatsapp.com/send/?phone=5511952244911" target="_blank" rel="noreferrer"><AiOutlineWhatsApp /> 11 95224-4911</a></li>
            </ul>
            <ul>
              <li><Link onClick={() => scrollSection('header')} to="">Home</Link></li>
              <li><Link onClick={() => scrollSection('about-us')} to="">Sobre Nós</Link></li>
              <li><Link onClick={() => scrollSection('hacktown')} to="">HackTown</Link></li>
              {/* <li><Link to="/">Blog</Link></li> */}
            </ul>
            <ul>
              <li><Link onClick={() => scrollSection('faq')} to="">Central de Ajuda</Link></li>
              {/* <li><Link to="/">Seja um Parceiro</Link></li> */}
              <li><Link onClick={() => scrollSection('contact')} to="">Fale Conosco</Link></li>
              {/* <li><Link to="/">Trabalhe Conosco</Link></li> */}
            </ul>
          </div>
        </div>
        <div className="divisor"></div>
        </>
        )}
        <div className="copyright">
          <span>Copyright <AiOutlineCopyrightCircle /> 2023 - Todos os direitos reservados</span>
          <span>Desenvolvido com <AiFillHeart /> pelo time <Link to="https://agenciaweber.com.br/" target="_blank">Agência Weber.</Link></span>
        </div>
      </section>
    </footer>
  );
}