import React, { useEffect, useState } from "react"
import {
  SearchFilter,
  SearchParams,
  SearchResultInfo,
  User,
} from "../../../../entities"
import Layout from "../../../../components/layout/Layout"
import Loader from "../../../../components/loader/Loader"
import { UserService } from "../../../../services/UserService"
import { useNavigate } from "react-router-dom"
import { Button } from "reactstrap"
import { GrEdit } from "react-icons/gr"
import DataTable from "../../../../components/dataTable/DataTable"

export default function StartupListPage() {
  const navigate = useNavigate()

  const [users, setUsers] = useState<User[]>([])
  const [resultInfo, setResultInfo] = useState(new SearchResultInfo())
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    load()
  }, [])

  const load = async (page = 1) => {
    setLoading(true)
    const roleFilter = new SearchFilter({ key: "roleCode", value: "startup" })
    const params = new SearchParams([roleFilter], 1, 9999)
    const result = await UserService.search(params)

    setUsers(result.data)
    setResultInfo(result.info)
    setLoading(false)
  }

  const renderActions = (params: any) => {
    return (
      <div className="table-action-button-container">
        <Button onClick={() => navigate(`/startup/${params.id}`)}>
          <GrEdit />
        </Button>
        {/* <Button onClick={() => deleteItem(params.id)} className="table-action-button"><GrTrash /></Button> */}
      </div>
    )
  }

  const columns = [
    {
      headerName: "Nome",
      renderCell: (params: any) => (
        <>{new User(params).getAttributeValue("name")}</>
      ),
    },
    { field: "email", headerName: "E-mail" },
    { headerName: "Ações", renderCell: renderActions },
  ]

  return (
    <Layout internal={true}>
      {loading && <Loader />}
      <section className="dashboard-form-page-container">
        <DataTable
          title="Startups"
          checkboxSelection={false}
          rows={users}
          columns={columns}
          pagination={resultInfo}
          onPageChange={(page: number) => load(page)}
          loading={loading}
          newLink={`/startup/0`}
        />
      </section>
    </Layout>
  )
}
