import { Answer, SearchParams, SearchResult } from "../../entities";
import ApiClient from "../clients/ApiClient"

const client = new ApiClient()
export class FileUploadRepository {

  public static async upload(formData: FormData, token: string): Promise<any> {
    const headers: any = {
      Authorization: token,
      'Content-Type': 'multipart/form-data',
    }
    
    return await client.DoRequest('POST', '/file/upload', formData, headers)
  }

}