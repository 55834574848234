import React, { useEffect, useState } from 'react';
import {
  Row, Col, FormGroup, Input, Label
} from 'reactstrap';
import { ExerciseProps } from '../base';
import { Answer } from '../../../entities';

import './Phase2Week6.scss';


export default function Phase2Week6(props: ExerciseProps) {
  const [data, setData] = useState({
    leadsNumber: props.answer.answer?.leadsNumber || '',
    reunionNumber: props.answer.answer?.reunionNumber || '',
    acquisitionNumber: props.answer.answer?.acquisitionNumber || '',
    withdrawalsNumber: props.answer.answer?.withdrawalsNumber || '',
  })

  useEffect(() => {
    const isCompleted = data.leadsNumber.length >= 1 && data.reunionNumber.length >= 1 && 
    data.acquisitionNumber.length >= 1 && data.withdrawalsNumber.length >= 1 

    const answer = new Answer({
      ...props.answer, 
      answer: {
        ...data
      },
      isCompleted
    })
    
    props.save(answer)

    // eslint-disable-next-line
  }, [data])

  return (
    <div className="page-content-container">
      <section className="exercises-container">
        <div className="title">
          <h1>{props.phase.name} - {props.week.name}</h1>
        </div>
        <form className="phase2-week6-form" action="">
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="leadsNumber"
                  name="leadsNumber"
                  type="number"
                  required
                  value={data.leadsNumber}
                  onChange={(event) => setData({...data, leadsNumber: event.target.value})}
                />
                <Label for="leadsNumber">
                  Número de Leads
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="reunionNumber"
                  name="reunionNumber"
                  type="number"
                  required
                  value={data.reunionNumber}
                  onChange={(event) => setData({...data, reunionNumber: event.target.value})}
                />
                <Label for="reunionNumber">
                  Número de Reuniões
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="acquisitionNumber"
                  name="acquisitionNumber"
                  type="number"
                  required
                  value={data.acquisitionNumber}
                  onChange={(event) => setData({...data, acquisitionNumber: event.target.value})}
                />
                <Label for="acquisitionNumber">
                  Número de Aquisições
                </Label>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup floating>
                <Input
                  id="withdrawalsNumber"
                  name="withdrawalsNumber"
                  type="number"
                  required
                  value={data.withdrawalsNumber}
                  onChange={(event) => setData({...data, withdrawalsNumber: event.target.value})}
                />
                <Label for="withdrawalsNumber">
                  Número de Desistências
                </Label>
              </FormGroup>
            </Col>
          </Row>
        </form>
      </section>
    </div>
  )
}